import rules from './fieldVisibilityRules';
import { reportBase } from './reportsBase';
import { reportTypes } from '../../utils/reports';

const TYPE = reportTypes.creativeExplore;

export default {
    type: TYPE,
    fields: [
        ...reportBase.fields,
        'discrepancyMetrics',
        'technicalSpecsDimensions',
        'custom_dimensions',
        'sourceDimensions',
        'creativeMetadataDimensions',
        'discrepancyMetrics',
        'cohortMetrics',
        'periods',
        'customEvents',
        'governanceDimensions',
    ],
    visibleRule: rules[TYPE],
    dataSelector: {
        ...reportBase.dataSelector,
        discrepancyMetrics: 'discrepancyMetrics',
        technicalSpecsDimensions: 'technicalSpecsDimensions',
        custom_dimensions: 'custom_dimensions',
        sourceDimensions: 'sourceDimensions',
        creativeMetadataDimensions: 'creativeMetadataDimensions',
        cohortMetrics: 'cohortMetrics',
        periods: 'periods',
        customEvents: 'customEvents',
        governanceDimensions: 'governanceDimensions',
        pivotTable: 'pivotTable',
        mode: 'mode',
        tableDataFormatted: 'reportData',
    },
    isCreativeExplore: true,
};
