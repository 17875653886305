import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { all, call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { handleFrontendCacheLoad } from 'customEvents/actions';
import {
    DEFAULT_ADMON_SOURCE_FILTER,
    DEFAULT_APP_FILTER,
    DEFAULT_FILTER_NAMES,
    DEFAULT_SOURCE_FILTER,
    fraudReportTypes,
    isCompareField,
    isDrilldownEnabled,
    isObject,
    reportTypes,
    translateQueryToStore,
    validateQuery,
} from 'utils/reports';
import { CUSTOMER_TIERS } from 'utils/consts';
import { tick } from '../../global/utils';

import {
    CLOSE_SHELF,
    DELETE_REPORT,
    GENERATE_ANONYMOUS_LINK,
    GENERATE_ANONYMOUS_LIST,
    RUN_ANONYMOUS_PAGE,
    runAnonymousPage,
    showPasswordModal,
    toggleDateSelection,
    UPDATE_ANONYMOUS_ID,
    updateCreator,
    updateError,
    updateReportName,
} from '../../actions/anonymous';
import { updatedFilters, updateFields } from '../../actions/fields';
import {
    CHART_LINES_CHANGED,
    chartResponse,
    CHECK_ALERTS_DATA,
    checkAlertsData,
    CLEAR_TOASTS,
    clearReportData,
    clearToasts,
    DATE_CHANGED_GLOBAL,
    dateChanged,
    DOWNLOAD_REPORT,
    EXPORT_TABLE,
    FIELD_CLICKED,
    FILTER_CHANGED,
    getExportAction,
    GRID_READY,
    hideChart,
    hideSpinner,
    hideTable,
    MODE_SELECTED_GENERIC,
    modeSelected,
    queryErrorResponse,
    queryRequestParams,
    queryResponse,
    reportsFieldClicked,
    RUN_QUERY,
    RUN_QUERY_ON_FIELD_CHECKED,
    runQuery as runQueryAction,
    setActionType,
    setChartError,
    setClearSelectionModeOff,
    setRunQueryFinished,
    setValidationError,
    showChart,
    showReportHeader,
    showTable,
    toggleShelf,
    toggleSlimMode,
    toggleSlowLoadingChart,
    totalsCleaned,
    totalsHide,
    totalsRequest,
    totalsResponse,
    transparencyDataResponse,
    UPDATE_CHART,
    UPDATE_QUERY_URL,
    updateAnonymousId,
    updateAnonymousList,
    updateBookmarkData,
    updateChartData,
    updateChartLines,
    updateReportData,
} from '../../actions/reports';
import { setApiKey, UPDATE_USER_DATA } from '../../actions/user';
import { checkActionForType } from '../../reducers/reports';
import reportConfigs from '../../reducers/reportsConfig';
import { getPassword, getSecretId } from '../../selectors/anonymous';
import { getFields, getFieldsForType, getFilters } from '../../selectors/fields';
import {
    getChartConfig,
    getConfigForType,
    getFrozenFilters,
    getIsClearSelectionOn,
    getPostProcessFiltersUI,
    getQueryDataForType,
    getQueryRunning,
    getReportDataForType,
    getReportDates,
    getShelfShown,
    getShouldDrilldown,
    getTableConfig,
    getTableFormattedData,
} from '../../selectors/reports';
import { getUserData } from '../../selectors/user';
import {
    AdMonetization,
    Charts,
    FraudProtected,
    FraudRejected,
    FraudSuspicious,
    Reports,
    SkanReport,
    SkanSummaryReport,
    UnifiedReport,
    CreativeExplore,
} from '../../services';
import GridService from '../../services/grid';
import { getExportFileName } from '../../utils/export';
import { getDefaultCompareDates, getDefaultDates } from '../../utils/regional';

import toaster from '../../utils/toaster';
import {
    deserializeReportQuery,
    getImpersonationFromQuery,
    searchParamsWithReportQuery,
    serializeReportQuery,
    serializeReportQueryEx,
    setUrlSearchParams,
} from '../../utils/url';
import Cancelable from '../Cancelable';
import {
    updateFieldsHandler,
    updateFilterFieldsHandler,
    updateReportsPage,
    updateSourcesFieldsAvailabilityHandler,
} from '../fields';

import { calculatedMetricValue, getGridFilteredData, shouldRowBeSkipped } from '../../utils/grids';
import { REPORT_QUERY_PREFIX, trackMixpanelEvent } from '../../utils/general';
import { COMPARE_MODE, REPORT_LOAD_TIME_SLOW_CHART } from '../../components/pages/reports/consts';
import {
    NETWORKS_SPECIAL_DISPLAY_NAME_MAPPING,
    NETWORKS_SPECIAL_ORIGINAL_DISPLAY_NAME_MAPPING,
} from '../../utils/adNetworks';
import { REPORT_EXCEEDS_SIZE_LIMIT_ERROR } from '../../utils/reportsConsts';
import { getTranslate } from '../../selectors/locale';

const cancelable = new Cancelable();

const reportsAPI = new Reports();
const chartsAPI = new Charts();
const gridService = new GridService();
const skanReportAPI = new SkanReport();
const skanSummaryReportAPI = new SkanSummaryReport();
const fraudProtectedAPI = new FraudProtected();
const unifiedReportAPI = new UnifiedReport();
const fraudSuspiciousAPI = new FraudSuspicious();
const fraudRejectedAPI = new FraudRejected();
const adMonetizationAPI = new AdMonetization();
const creativeExploreAPI = new CreativeExplore();

const queryTypeToAPI = {
    reports: reportsAPI,
    pivot: reportsAPI,
    crossDevice: reportsAPI,
    fraudProtected: fraudProtectedAPI,
    fraudSuspicious: fraudSuspiciousAPI,
    fraudRejected: fraudRejectedAPI,
    charts: chartsAPI,
    adMonetization: adMonetizationAPI,
    skan: skanReportAPI,
    skanSummary: skanSummaryReportAPI,
    unifiedReport: unifiedReportAPI,
    creativeExplore: creativeExploreAPI,
};

const GRID_OPERATOR_TO_API_OPERATOR = {
    lessThan: 'lt',
    greaterThan: 'gte',
};

function* clearReport(reportType, shelfOpen = false) {
    yield all([
        put(setActionType(hideChart(), reportType)),
        put(setActionType(hideTable(), reportType)),
        put(setActionType(toggleShelf(shelfOpen), reportType)),
        put(setActionType(setValidationError(''), reportType)),
        put(clearToasts()),
        put(setActionType(updateAnonymousId(null), reportType)),
        put(setActionType(clearReportData(), reportType)),
    ]);
}

function* onload(action) {
    const start = new Date().getTime();
    const reportConfig = reportConfigs.find(config => config.path === action.payload.pathname);
    const reportType = reportConfig.type;
    const isRunning = yield select(getQueryRunning(reportType));

    if (!isRunning) {
        // Update the fields. if there’s no search data (i.e. pristine) - base on defaults. Otherwise - base on the query
        yield call(updateFieldsHandler, !searchParamsWithReportQuery(action.payload.search), [reportType]);
        yield fork(updateFilterFieldsHandler);
        yield fork(updateSourcesFieldsAvailabilityHandler);
    }

    if (searchParamsWithReportQuery(action.payload.search)) {
        // when the run button is hit, we run the query and change the url.
        // the following line makes sure we're not running it again
        try {
            const userData = yield select(getUserData);
            let forceValues = {};

            if (
                !userData.show_report_toggle ||
                (action.payload.search && action.payload.search.includes('bookmark_id'))
            ) {
                forceValues = { is_slim_mode: false };
            }

            const reportQuery = deserializeReportQuery(action.payload.search, forceValues);
            const fields = yield select(getFields);
            const store = translateQueryToStore(reportQuery, fields);

            if (!store.dates.start_date || !store.dates.end_date) {
                store.dates = {
                    ...store.dates,
                    ...getDefaultDates(),
                };
            }
            const isStandardAnalytics = window.organization_tier === CUSTOMER_TIERS.STANDARD_ANALYTICS;
            const defaultFieldsToAdd = [];

            if (!isStandardAnalytics) {
                defaultFieldsToAdd.push(
                    store.filters[store.filters.findIndex(element => element.field === DEFAULT_APP_FILTER.field)] ||
                        DEFAULT_APP_FILTER
                );
            }

            if (reportType !== 'adMonetization') {
                defaultFieldsToAdd.push(
                    store.filters[store.filters.findIndex(element => element.field === DEFAULT_SOURCE_FILTER.field)] ||
                        DEFAULT_SOURCE_FILTER
                );
            } else {
                defaultFieldsToAdd.push(
                    store.filters[
                        store.filters.findIndex(element => element.field === DEFAULT_ADMON_SOURCE_FILTER.field)
                    ] || DEFAULT_ADMON_SOURCE_FILTER
                );
            }

            store.filters = [
                ...defaultFieldsToAdd,
                ...store.filters.filter(element => !DEFAULT_FILTER_NAMES.includes(element.field)),
            ];

            if (!store.dates.start_date_2 || !store.dates.end_date_2 || store.mode !== COMPARE_MODE) {
                store.dates = {
                    ...store.dates,
                    ...getDefaultCompareDates(store.dates.start_date, store.dates.end_date),
                };
            }

            yield put(setActionType(updateReportData(store), reportType));

            if (store.api_key) {
                yield put(setApiKey(store.api_key));
            }

            // runQueryAction needs to run after the updateReportData - as it selects the needed data from the store
            yield put(setActionType(runQueryAction(reportType, reportType, null, store.bookmark, true), reportType));
        } catch (e) {
            toaster.error('The report link is broken.');
            yield call(updateFieldsHandler, true, [reportType]);
        }
    } else {
        yield call(clearReport, reportType, true);
        yield put(setActionType(hideSpinner(), reportType));
        yield put(setActionType(showReportHeader(), reportType));
    }

    yield call(tick);
    yield put(updateFields([false, [], true]));
    const end = new Date().getTime();
    yield put(handleFrontendCacheLoad((end - start) / 1000));
}

function* destroy(currentPage) {
    const reportConfig = reportConfigs.find(config => config.path === currentPage.pathname);
    const reportType = reportConfig.type;
    yield call(clearReport, reportType, true);
    yield put(setActionType(queryResponse(null), reportType));
}

//  on mode change, changes the default selected fields.
function* updateSelectedFields(action) {
    const { id, reportType } = action;
    yield put(setActionType(modeSelected(id), reportType));
    const fieldKeys = ['metrics', 'cohortMetrics', 'discrepancyMetrics'];
    const fieldsValues = {};
    const isClearSelectionOn = yield select(getIsClearSelectionOn(reportType));

    if (!isClearSelectionOn) {
        for (const fieldKey of fieldKeys) {
            fieldsValues[fieldKey] = yield select(getFieldsForType(fieldKey));
        }
        yield call(updateReportsPage, fieldsValues, [reportType]);
    }
}

function* handleUserDataUpdated(action) {
    const isSlimMode = action.data && action.data.show_report_toggle;

    yield put(setActionType(toggleSlimMode(isSlimMode), reportTypes.mainReport));
    yield put(setActionType(toggleSlimMode(isSlimMode), reportTypes.pivot));
}

function* fetchChartData(action) {
    const { reportType } = action;
    // initialize showError value
    yield put(setActionType(setChartError(false), reportType));
    // Query for charts data
    const selectedChartData = yield select(getQueryDataForType(reportType, 'charts'));
    // TODO: should re-design and change .is_goals to class handling
    const dailyBreakdownNoDim =
        selectedChartData.filters.length === 0 &&
        selectedChartData.dimensions.length === 0 &&
        selectedChartData.valid_key_list_for_charts.length > 0;
    if (selectedChartData.filters.length || dailyBreakdownNoDim || selectedChartData.is_goals) {
        yield put(setActionType(queryRequestParams('chart', selectedChartData), reportType));
        try {
            const chartQueryResponse = yield call(queryTypeToAPI[reportType].runQuery, selectedChartData);
            yield put(setActionType(chartResponse(chartQueryResponse), reportType));
        } catch (e) {
            yield put(setActionType(hideChart(), reportType));
        }
    } else {
        console.error('Chart request has no filters', selectedChartData);
        yield put(setActionType(chartResponse(null), reportType));
        yield put(setActionType(setChartError(true), reportType));
    }
}

function updateQueryURL(selectedReportsData, postProcessFiltersUI, columnOrder) {
    // set query url
    const reportQueryUrl = serializeReportQuery(selectedReportsData, postProcessFiltersUI, columnOrder);
    const reportQueryUrlDecoded = serializeReportQueryEx(selectedReportsData, postProcessFiltersUI);
    // window.history.replaceState(null, null, setUrlSearchParams(location.href, reportQueryUrl));
    const event = new CustomEvent('routelessRedirect', {
        detail: {
            url: setUrlSearchParams('', window.location.search, reportQueryUrl),
            urlDecoded: setUrlSearchParams(window.location.href, window.location.search, reportQueryUrlDecoded),
        },
    });
    window.dispatchEvent(event);
}

function* updateQueryURLFromAction(action) {
    const { reportType } = action;

    const selectedReportsData = yield select(getQueryDataForType(reportType));
    const postProcessFiltersUI = yield select(getPostProcessFiltersUI(reportType));
    const { column_order: columnOrder } = yield select(getTableConfig(reportType));

    updateQueryURL(selectedReportsData, postProcessFiltersUI, columnOrder);
}

function* shouldDrilldown(reportType, requestParams) {
    const tableConfig = yield select(getTableConfig(reportType));
    const state = yield select();

    return isDrilldownEnabled(reportType, tableConfig?.drilldownConfig, state, { requestParams });
}

function* generateAndValidateQuery(
    reportType,
    queryType,
    isAsync = false,
    isDownload = false,
    isExportGsheet = false,
    isCopyCsv = false,
    gsheetToken = null
) {
    const selectedReportsData = yield select(getQueryDataForType(reportType, queryType));
    const userData = yield select(getUserData);
    selectedReportsData.is_async = isAsync;
    selectedReportsData.is_download_report = isDownload;
    selectedReportsData.should_drilldown = yield shouldDrilldown(reportType, selectedReportsData);
    selectedReportsData.is_export_gsheet = isExportGsheet;
    selectedReportsData.is_copy_csv = isCopyCsv;
    selectedReportsData.gsheet_token = gsheetToken;

    const { result, message } = validateQuery(selectedReportsData, userData.report_date_limit?.restrictions);

    if (!result) {
        yield put(setActionType(setValidationError(message), reportType));
        return null;
    }

    return selectedReportsData;
}
const _getDurationInSeconds = startTimer => {
    return (new Date().getTime() - startTimer) / 1000.0;
};

const _getDurationBucket = durationInSeconds => {
    if (typeof durationInSeconds !== 'number' || isNaN(durationInSeconds)) return 'Undefined';
    if (durationInSeconds < 0) return 'Unknown';
    if (durationInSeconds < 2) return '0s-2s';
    if (durationInSeconds < 4) return '2s-4s';
    if (durationInSeconds < 7) return '4s-7s';
    if (durationInSeconds < 10) return '7s-10s';
    if (durationInSeconds < 25) return '10s-25s';
    if (durationInSeconds < 40) return '25s-40s';
    if (durationInSeconds < 60) return '40s-60s';
    if (durationInSeconds < 120) return '1m-2m';
    if (durationInSeconds < 240) return '2m-4m';
    if (durationInSeconds < 360) return '4m-6m';
    if (durationInSeconds < 480) return '6m-8m';
    if (durationInSeconds < 600) return '8m-10m';
    if (durationInSeconds < 900) return '10m-15m';
    if (durationInSeconds < 1200) return '15m-20m';
    if (durationInSeconds < 1800) return '20m-30m';
    return '>30m';
};

const _getNumRowsBucket = numRows => {
    if (typeof numRows !== 'number' || isNaN(numRows)) return 'Undefined';
    if (numRows < 0) return 'Unknown';
    if (numRows < 1) return '0';
    if (numRows < 100) return '1-100';
    if (numRows < 1000) return '100-1k';
    if (numRows < 5000) return '1k-5K';
    if (numRows < 10000) return '5K-10K';
    if (numRows < 30000) return '10K-30K';
    if (numRows < 50000) return '30K-50K';
    if (numRows < 75000) return '50K-75K';
    if (numRows < 100000) return '75K-100K';
    if (numRows < 300000) return '100K-300K';
    if (numRows < 500000) return '300K-500K';
    if (numRows < 750000) return '500K-750K';
    if (numRows < 1000000) return '750K-1M';
    if (numRows < 3000000) return '1M-3M';
    if (numRows < 5000000) return '3M-5M';
    if (numRows < 10000000) return '5M-10M';
    return '>10m';
};

function* fetchTableData(reportType, queryType) {
    const startTimer = new Date().getTime();
    // Query for reports data
    yield put(setActionType(checkAlertsData(reportType), reportType));

    const selectedReportsData = yield generateAndValidateQuery(reportType, queryType);
    if (!selectedReportsData) {
        return null;
    }

    // store a snapshot of the params at query time (as the selectors are bound to the shelf, and will update when it changes).
    yield put(setActionType(queryRequestParams('table', selectedReportsData), reportType));

    const postProcessFiltersUI = yield select(getPostProcessFiltersUI(reportType));
    const { column_order: urlColumnOrder, columnOrder } = yield select(getTableConfig(reportType));

    updateQueryURL(selectedReportsData, postProcessFiltersUI, columnOrder || urlColumnOrder);

    try {
        const response = yield call(queryTypeToAPI[queryType].runQuery, selectedReportsData);
        if (typeof response === 'string' && response.includes(REPORT_EXCEEDS_SIZE_LIMIT_ERROR)) {
            const translate = yield select(getTranslate);
            toaster.error(translate('STATIC.PAGES.REPORTS.REPORT_EXCEEDS_SIZE_LIMIT_ERROR'));
            const durationInSeconds = _getDurationInSeconds(startTimer);
            const durationBucket = _getDurationBucket(durationInSeconds);
            yield put(setActionType(queryErrorResponse(response, durationBucket), reportType));
            return null;
        }

        response.durationInSeconds = _getDurationInSeconds(startTimer);
        response.durationBucket = _getDurationBucket(response.durationInSeconds);
        response.numRowsBucket = _getNumRowsBucket(response.num_of_rows);

        yield put(setActionType(queryResponse(response), reportType));
        yield put(setActionType(showTable(), reportType));
        return selectedReportsData;
    } catch (e) {
        console.error(e);
        toaster.error('An unexpected error has occurred, please try again later');
        const durationInSeconds = _getDurationInSeconds(startTimer);
        const durationBucket = _getDurationBucket(durationInSeconds);
        yield put(setActionType(queryErrorResponse(e.message, durationBucket), reportType));
        return null;
    }
}

function* _transparencyRequest(reportType) {
    const { reportId } = yield select(getTableFormattedData(reportType));
    const startTime = new Date().getTime();
    let response = {};
    let result = false;
    try {
        response = yield call(reportsAPI.getTransparencyData, reportId);
        result = true;
    } catch (e) {
        console.error(e);
    }
    return { response, duration: new Date().getTime() - startTime, result };
}

function* _transparencyAct(response, reportType, duration, result) {
    yield put(setActionType(transparencyDataResponse(response, duration, result), reportType));
}

// eslint-disable-next-line no-unused-vars
function* handleTransparencyData(reportType) {
    const { response, duration, result } = yield _transparencyRequest(reportType);
    yield _transparencyAct(response, reportType, duration, result);
}

// the private version of handleTransparencyData has delay in it. Should we use the public version, the transperency and
// the chart will fire, and resolve, short thereafter, ensuing with a notable time of irresponsiveness.
// WARNING do not use this version (use the public one) as this one's delayed.
export function* _handleTransparencyData(reportType, span = 350) {
    const { response, duration, result } = yield _transparencyRequest(reportType);
    yield delay(span);
    yield _transparencyAct(response, reportType, duration, result);
}

function* runQueryOnFieldClicked(action) {
    const { field, isSingle, reportType, queryType, bookmark } = action;

    yield put(setActionType(reportsFieldClicked(field, isSingle), reportType));
    yield call(runQuery, { reportType, queryType, bookmark });
}

function* downloadOrExportReport(action) {
    const { reportType, queryType, dontExport, origin, isExportGsheet, gsheetToken, isCopyCsv } = action;

    const shelfShown = yield select(getShelfShown(reportType));
    const table = yield select(getTableConfig(reportType));
    if (!shelfShown && !dontExport && table.request) {
        yield fork(handleExportTable, action);
        return;
    }

    const selectedReportsData = yield generateAndValidateQuery(
        reportType,
        queryType,
        true,
        true,
        isExportGsheet,
        isCopyCsv,
        gsheetToken
    );

    if (!selectedReportsData) {
        return;
    }

    const downloadWindow = window.open('about:blank', '_blank');
    if (!downloadWindow) {
        window.alert('Please enable popup windows and retry');
        return;
    }

    try {
        const response = yield call(queryTypeToAPI[queryType].runAsyncReport, selectedReportsData);
        if (!response.report_id) {
            return;
        }
        const exportAction = getExportAction(isExportGsheet, isCopyCsv);

        trackMixpanelEvent(REPORT_QUERY_PREFIX, 'Export', {
            report_id: response.report_id,
            async_download: true,
            report_type: reportType,
            action: exportAction,
            origin,
        });
        let url = `/logs/download?report_id=${response.report_id}`;
        const impersonatedUser = getImpersonationFromQuery();
        if (impersonatedUser) {
            url += `&user=${decodeURIComponent(impersonatedUser)}`;
        }
        downloadWindow.location.href = url;
        downloadWindow.focus();
    } catch (e) {
        console.error(e);
    }
}

function* isChartEnabled(reportType, isCompareMode) {
    const chartConfig = yield select(getChartConfig(reportType));
    return !!chartConfig?.enabled && !isCompareMode;
}

export const runQuery = cancelable.wrap(function* runQueryInner(action) {
    const { reportType, queryType, bookmark } = action;

    yield call(clearReport, reportType);

    if (bookmark && bookmark.bookmark_id) {
        yield put(setActionType(updateBookmarkData(bookmark), reportType));
    }

    const fetchTableStart = new Date().getTime();
    const tableRequest = yield call(fetchTableData, reportType, queryType);

    if (!tableRequest) {
        yield put(setActionType(toggleShelf(true), reportType));
    } else {
        yield take(GRID_READY);

        const shouldCallChart = yield isChartEnabled(reportType, !!tableRequest.compare);

        if (shouldCallChart) {
            const fetchTableEnd = new Date().getTime();

            if (fetchTableEnd - fetchTableStart > REPORT_LOAD_TIME_SLOW_CHART) {
                yield put(setActionType(toggleSlowLoadingChart(true), reportType));
                yield put(setActionType(showChart(), reportType));
                yield put(updateChartData(reportType));
            } else {
                yield put(setActionType(hideChart(), reportType));
                yield put(updateChartData(reportType));
            }
        }

        const shouldDrilldown = yield select(getShouldDrilldown(reportType));

        if (shouldDrilldown) {
            yield fork(_handleTransparencyData, reportType);
        }
    }

    yield put(setActionType(setRunQueryFinished(), reportType));
});

function* handleAnonymousChartQuery(reportType) {
    const password = yield select(getPassword);
    const secretId = yield select(getSecretId);
    yield put(setActionType(showChart(), reportType));
    const selectedChartData = yield select(getQueryDataForType(reportType, 'charts'));
    selectedChartData.chart = true;
    yield put(setActionType(queryRequestParams('chart', selectedChartData), reportType));

    try {
        const responseChart = yield call(reportsAPI.runAnonymousQuery, secretId, password, selectedChartData);
        responseChart.total = undefined;
        yield put(setActionType(chartResponse(responseChart), reportType));
    } catch (e) {
        console.error(e);
    }
}

function* handleChartLinesChanged(action) {
    const { reportType, value } = action;
    yield put(setActionType(updateChartLines(value), reportType));
    if (reportType.includes('anonymous')) {
        yield call(handleAnonymousChartQuery, reportType);
    } else {
        yield put(updateChartData(reportType));
    }
}

function _extrapolateText(eivar, def) {
    let text;
    if (def.type === 'dimension') {
        text = eivar.filter.toLowerCase().trim() !== 'n/a' || eivar.field === 'country_field' ? eivar.filter : null;
    } else {
        text = Number(eivar.filter);
    }
    return text;
}

function _extrapolateOperator(quickFilter, def) {
    let operator = GRID_OPERATOR_TO_API_OPERATOR[quickFilter.type] || quickFilter.type;
    operator = operator === 'contains' && def.type === 'metric' ? 'equals' : operator;
    return operator;
}

function _translateGridFilterModelToAPI(model, filters, columnDefs) {
    const postProcessFilters = [];
    const queryFilters = [];

    const dimensionsToValues = filters
        .map(({ name, values }) => ({ [name]: values }))
        .reduce((a, b) => ({ ...a, ...b }), {});

    for (const quickFilter of model) {
        const dimensionName = quickFilter.field;
        const def = columnDefs.find(c => dimensionName === c.field);
        const textValue = _extrapolateText(quickFilter, def);

        if (dimensionName in dimensionsToValues) {
            const values =
                textValue !== null
                    ? dimensionsToValues[dimensionName]
                          .filter(({ name: currentName, display_name: currentDisplayName }) => {
                              let displayName = currentDisplayName;

                              if (NETWORKS_SPECIAL_DISPLAY_NAME_MAPPING[currentName] === currentDisplayName) {
                                  displayName = NETWORKS_SPECIAL_ORIGINAL_DISPLAY_NAME_MAPPING[currentName];
                              }

                              return displayName.toLocaleLowerCase().includes(textValue.toLocaleLowerCase());
                          })
                          .map(({ name }) => name)
                    : [null];

            if (!values.length) return null;

            queryFilters.push({
                dimension: dimensionName,
                operator: 'in',
                values,
            });
        } else {
            postProcessFilters.push({
                field: dimensionName,
                value: textValue,
                operator: textValue !== null ? _extrapolateOperator(quickFilter, def) : 'equals',
            });
        }
    }

    return {
        post_process_filters: postProcessFilters.length ? postProcessFilters : undefined,
        filters: queryFilters,
    };
}

export function* fetchReportTotals(action) {
    const { reportType, model, colDefs } = action;

    const filters = yield select(getFilters);

    const filteredRows = getGridFilteredData(reportType);

    if (!filteredRows?.length) {
        yield put(setActionType(totalsHide(), reportType));
    } else if (Object.keys(model).length > 0) {
        const translated = _translateGridFilterModelToAPI(model, filters, colDefs);

        if (!translated) {
            yield put(setActionType(totalsHide(), reportType));
            return;
        }

        let queryPayload = yield select(getQueryDataForType(reportType));
        const filtersNames = new Set(translated.filters.map(filter => filter.dimension));
        const baseFilters = queryPayload.filters.filter(filter => !filtersNames.has(filter.dimension));
        const mergedFilters = [...baseFilters, ...translated.filters];
        queryPayload = {
            ...queryPayload,
            ...translated,
            filters: mergedFilters,
            is_totals: true,
        };

        const startTime = performance.now();
        yield put(setActionType(totalsRequest(), reportType));

        let response;

        if (reportType === 'anonymousReport') {
            const secretId = yield select(getSecretId);
            const password = yield select(getPassword);
            response = yield call(reportsAPI.runAnonymousQuery, secretId, password, queryPayload, { suffix: 'totals' });
        } else {
            response = yield call(queryTypeToAPI[reportType].runQuery, queryPayload, { channel: 'TOTALS' });
        }

        const endTime = performance.now();
        yield put(setActionType(totalsResponse(response.total, (endTime - startTime) / 1000), reportType));
    } else {
        yield put(setActionType(totalsCleaned(), reportType));
    }
}

function* handleTableFilterChange(action) {
    const { reportType } = action;
    const pivotTable = yield select(getConfigForType(reportType).selectors.pivotTable);

    // NO need to do anything on pivot filter change
    if (
        reportType === reportTypes.pivot ||
        reportType === reportTypes.creativeExplore ||
        (reportType === 'anonymousReport' && pivotTable && Object.keys(pivotTable).length)
    ) {
        return;
    }

    yield fork(fetchReportTotals, action);

    if (reportType.includes('anonymous')) {
        yield fork(handleAnonymousChartQuery, reportType);
    } else {
        const shouldCallChart = yield isChartEnabled(reportType);

        if (shouldCallChart) {
            yield put(updateChartData(reportType));
        }
    }
}

function* handleDatesChangedGlobal(action) {
    const { data, reportType } = action;
    yield put(setActionType(dateChanged(data), reportType));
    if (reportType.includes('anonymous')) {
        yield put(runAnonymousPage());
    } else {
        const { reportData } = yield select(getReportDataForType(reportType));
        if (reportData && Object.keys(reportData).length) {
            yield put(runQueryAction(reportType, reportType));
        }
    }
}

function* handleGenerateAnonymousList() {
    const response = yield call(reportsAPI.generateAnonymousReportsList, {});
    yield put(setActionType(updateAnonymousList(response)));
}

function* handleGenerateAnonymousLink(action) {
    const { name, password, expirationDays, flexibleDates, reportType } = action;
    const tableConfig = yield select(getTableConfig(reportType));
    // Add a fraud type for filtering fraud data
    if (fraudReportTypes.includes(reportType)) {
        tableConfig.request.fraud_type = reportType.replace('fraud', '').toLowerCase();
    }
    const filters = yield select(getFrozenFilters(reportType));
    const response = yield call(reportsAPI.generateSharedReportLink, {
        report_name: name,
        report_password: password,
        expiration_days: expirationDays,
        query: {
            query: tableConfig.request,
            available_filters: filters,
        },
        flexible_dates: flexibleDates,
        url: window.location.href,
        secret_id: null,
        shared_reports_list: null,
    });
    yield put(setActionType(updateAnonymousId(response.value.secret_id), reportType));
    yield put(setActionType(updateAnonymousList(response.value.shared_reports_list)));
}

function* handleShelfClose(action) {
    const { reportType } = action;

    yield put(setActionType(updateAnonymousId(''), reportType));
}

function* handleDeleteReport(action) {
    const { secretId } = action;
    const response = yield call(reportsAPI.deleteSharedReport, {
        secretId,
        deleted: false,
        shared_reports_list: null,
    });
    yield put(setActionType(updateAnonymousList(response.value.shared_reports_list)));
}

function* handleClearToasts() {
    toaster.dismiss();
    yield delay(0);
}

function* handleCheckAlertsData(action) {
    const { reportType } = action;
    const userData = yield select(getUserData);

    // continue only for non xorg users
    if (userData.xorg_count !== 0) {
        return Promise.resolve();
    }

    const selectedReportsData = yield select(getQueryDataForType(reportType));
    const {
        start_date,
        end_date,
        metrics = [],
        discrepancy_metrics = [],
        cohort_metrics = [],
        is_fraud,
        is_admon_report,
    } = selectedReportsData;
    const metricsCopy = [...new Set([...metrics, 'adn_cost'])];
    const columns = { metrics, discrepancy_metrics, cohort_metrics };
    const filters = yield select(getConfigForType(reportType).selectors.formattedFilters);

    try {
        const resp = yield call(reportsAPI.getAlertsData, {
            api_params: {
                start_date,
                end_date,
                metrics: metricsCopy,
                columns,
                filters,
                is_fraud,
                is_admon_report,
            },
        });
        const reportAlerts = resp.alerts;
        const { restrictions } = resp;
        const columnRestrictions = restrictions.column || [];

        const alertsCount = reportAlerts.length;
        const columnRestrictionsCount = columnRestrictions.length;
        const maxSources = 3;

        put(clearToasts());
        if (alertsCount > 0) {
            let alertMsg = '';

            if (alertsCount <= maxSources) {
                reportAlerts.forEach((src, ind) => {
                    alertMsg += src + (reportAlerts.length === ind + 1 ? '' : ', ');
                });
            } else {
                alertMsg = `${reportAlerts[0]}, ${reportAlerts[1]}, ${reportAlerts[2]} and ${(
                    alertsCount - 3
                ).toString()} other`;
            }

            toaster.error(
                `We are experiencing issues with logging into ${alertMsg}. Please visit the Data Connectors page to validate and update the credentials.`
            );
        }

        if (columnRestrictionsCount > 0) {
            const columnRestrictionsMessage = `'${columnRestrictions.join("', '")}'`;
            toaster.warning(
                `The following columns are restricted for this user, Please remove them from the query: ${columnRestrictionsMessage}`
            );
        }
    } catch (e) {
        console.error(e);
    }
}

function* handleExportTable(action) {
    const { reportType, isExportGsheet, gsheetToken } = action;
    const table = yield select(getTableConfig(reportType));
    const { request } = table;

    const exportParams = {
        fileName: getExportFileName(
            request.time_breakdown[0],
            {
                start_date: request.start_date,
                end_date: request.end_date,
                start_date_2: request.start_date_2,
                end_date_2: request.end_date_2,
            },
            request.compare
        ),
        allColumns: true,
        skipPinnedTop: true,
        shouldRowBeSkipped,
        processCellCallback: props => {
            const { value, column } = props;
            if (isCompareField(column.colId)) return props.column.colDef.valueFormatter(props);
            return isObject(value) ? calculatedMetricValue(value) : value;
        },
    };

    const reportConfig = getConfigForType(reportType);

    if (reportConfig?.getExportHeader) {
        exportParams.processHeaderCallback = ({ column }) => {
            const { colDef } = column;
            return reportConfig.getExportHeader(colDef);
        };
    }

    if (reportType === 'pivot') {
        exportParams.allColumns = false;
    }

    const api = gridService.gridApi(`report_${reportType}`);

    if (isExportGsheet) {
        const csvData = api.getDataAsCsv(exportParams);
        const resp = yield call(reportsAPI.exportDataToGsheet, exportParams.fileName, gsheetToken, csvData);
        if (resp.error_message === 'report_too_big') {
            const translate = yield select(getTranslate);
            toaster.error(translate('STATIC.PAGES.REPORTS.GSHEET_REPORT_TOO_BIG'));
            return null;
        }
        if (resp) {
            const gsheetUrl = resp.gsheet_url;
            const downloadWindow = window.open('about:blank', '_blank');
            downloadWindow.location.href = gsheetUrl;
            downloadWindow.focus();
        }
    } else {
        api.exportDataAsCsv(exportParams);
    }

    yield delay(0);
}

function* handleAnonymousError(errorMessage) {
    yield put(updateError(errorMessage));
}

function* handleAnonymousSuccess(secretId, password, reportType, response) {
    const { query: responseQuery, available_filters: availableFilters } = response.query;
    if (responseQuery.compare) {
        yield put(modeSelected(COMPARE_MODE));
    }
    if (response.flexible_dates) {
        yield put(toggleDateSelection(true));
    }
    if (response.report_creator) {
        yield put(updateCreator(response.report_creator));
    }
    if (response.reportName) {
        yield put(updateReportName(response.reportName));
    }
    yield put(setActionType(queryRequestParams('table', responseQuery), reportType));
    yield put(
        setActionType(
            updateReportData({
                selectedFields: {
                    dimensions: responseQuery.dimensions,
                    cohortMetrics: responseQuery.cohort_metrics,
                    cohortPeriods: responseQuery.cohort_periods,
                    timeBreakdowns: responseQuery.time_breakdown,
                    sourceAttributionType: responseQuery.source_attribution_type,
                    metrics: responseQuery.metrics,
                    discrepancyMetrics: responseQuery.discrepancy_metrics,
                },
                filters: responseQuery.filters.map(f => ({
                    operator: f.operator,
                    tags: f.values,
                    field: f.dimension,
                })),
                dates: {
                    start_date: responseQuery.start_date,
                    end_date: responseQuery.end_date,
                    start_date_2: responseQuery.start_date_2,
                    end_date_2: responseQuery.end_date_2,
                },
                pivotTable: responseQuery.query_type === 'pivot' ? responseQuery.pivot_table : {},
            }),
            reportType
        )
    );
    yield put(setActionType(queryResponse(response), reportType));
    yield put(
        updatedFilters({
            dimensions: availableFilters,
        })
    );
    yield put(setActionType(showTable(), reportType));
    yield call(handleAnonymousChartQuery, reportType);
}

function* handleAnonymousRun() {
    const reportType = 'anonymousReport';
    const secretId = yield select(getSecretId);
    const password = yield select(getPassword);
    yield all([
        put(setActionType(hideChart(), reportType)),
        put(setActionType(hideTable(), reportType)),
        put(showLoading()),
    ]);
    if (secretId) {
        let query = '';
        let errorMessage = 'STATIC.PAGES.ANONYMOUS.ERROR_INVALID';
        const tableConfig = yield select(getTableConfig(reportType));
        if (tableConfig.request) {
            query = tableConfig.request;
            const dates = yield select(getReportDates(reportType));
            Object.assign(query, dates);
        }
        let response;
        try {
            response = yield call(reportsAPI.runAnonymousQuery, secretId, password, query);
        } catch (err) {
            yield put(setActionType(queryResponse(response), reportType));
            if (err.message === Reports.ERROR_ANONYMOUS_INVALID_EMAIL.message) {
                errorMessage = 'STATIC.PAGES.ANONYMOUS.ERROR_INVALID_EMAIL';
            }
            if (err.message === Reports.ERROR_ANONYMOUS_EXPIRED_ID.message) {
                errorMessage = 'STATIC.PAGES.ANONYMOUS.ERROR_EXPIRED';
            }
            if (err.message === Reports.ERROR_ANONYMOUS_INVALID_ID.message) {
                errorMessage = 'STATIC.PAGES.ANONYMOUS.ERROR_INVALID';
            }
        }
        if (!response) {
            yield call(handleAnonymousError, errorMessage);
        } else {
            yield call(handleAnonymousSuccess, secretId, password, reportType, response);
        }
        yield put(hideLoading());
    }
}

function* handleAnonymousSecretIdUpdate(action) {
    const { secretId } = action;
    const hasPassword = yield call(reportsAPI.hasPassword, secretId);
    if (hasPassword) {
        yield put(showPasswordModal());
    } else {
        yield put(runAnonymousPage());
    }
}

/******************************************************************************/
/** ***************************** WATCHERS *************************************/

/******************************************************************************/

function* watchReportsModeSelected() {
    while (true) {
        const action = yield take(MODE_SELECTED_GENERIC);
        yield fork(updateSelectedFields, action);
    }
}

function _runQueryWatcher(reportType) {
    function* watchRunQuery() {
        yield takeLatest(checkActionForType(RUN_QUERY, reportType), runQuery);
        yield takeEvery(checkActionForType(UPDATE_QUERY_URL, reportType), updateQueryURLFromAction);
    }
    return watchRunQuery;
}

function* watchChartLinesChanged() {
    while (true) {
        const action = yield take(CHART_LINES_CHANGED);
        yield fork(handleChartLinesChanged, action);
    }
}

function* watchTableFilterChange() {
    yield takeLatest(FILTER_CHANGED, handleTableFilterChange);
}

function* watchDatesChangedGlobal() {
    while (true) {
        const action = yield take(DATE_CHANGED_GLOBAL);
        yield fork(handleDatesChangedGlobal, action);
    }
}

function* watchGenerateAnonymousList() {
    while (true) {
        const action = yield take(GENERATE_ANONYMOUS_LIST);
        yield fork(handleGenerateAnonymousList, action);
    }
}

function* watchGenerateAnonymousLink() {
    while (true) {
        const action = yield take(GENERATE_ANONYMOUS_LINK);
        yield fork(handleGenerateAnonymousLink, action);
    }
}

function* watchShelfClose() {
    while (true) {
        const action = yield take(CLOSE_SHELF);
        yield fork(handleShelfClose, action);
    }
}

function* watchDeleteReport() {
    while (true) {
        const action = yield take(DELETE_REPORT);
        yield fork(handleDeleteReport, action);
    }
}

function* watchExportTable() {
    while (true) {
        const action = yield take(EXPORT_TABLE);
        yield fork(handleExportTable, action);
    }
}

function* watchAnonymousSecretIdUpdate() {
    while (true) {
        const action = yield take(UPDATE_ANONYMOUS_ID);
        yield fork(handleAnonymousSecretIdUpdate, action);
    }
}

function* watchAnonymousPageRun() {
    while (true) {
        yield take(RUN_ANONYMOUS_PAGE);
        yield fork(handleAnonymousRun);
    }
}

function* watchDownloadReport() {
    yield takeLatest(checkActionForType(DOWNLOAD_REPORT, reportTypes.mainReport), downloadOrExportReport);
}

function* watchGetAlertsData() {
    yield takeLatest(checkActionForType(CHECK_ALERTS_DATA, reportTypes.mainReport), handleCheckAlertsData);
    yield takeLatest(checkActionForType(CHECK_ALERTS_DATA, reportTypes.pivot), handleCheckAlertsData);
    yield takeLatest(checkActionForType(CHECK_ALERTS_DATA, reportTypes.fraudProtected), handleCheckAlertsData);
    yield takeLatest(checkActionForType(CHECK_ALERTS_DATA, reportTypes.fraudSuspicious), handleCheckAlertsData);
    yield takeLatest(checkActionForType(CHECK_ALERTS_DATA, reportTypes.fraudRejected), handleCheckAlertsData);
    yield takeLatest(checkActionForType(CHECK_ALERTS_DATA, reportTypes.adMonetization), handleCheckAlertsData);
    yield takeLatest(checkActionForType(CHECK_ALERTS_DATA, reportTypes.crossDevice), handleCheckAlertsData);
}

function* watchClearToasts() {
    yield takeEvery(CLEAR_TOASTS, handleClearToasts);
}

function* watchRunQueryOnFieldClicked() {
    yield takeLatest(checkActionForType(RUN_QUERY_ON_FIELD_CHECKED, reportTypes.mainReport), runQueryOnFieldClicked);
}

function* watchUpdateChart() {
    yield takeEvery(UPDATE_CHART, fetchChartData);
}

function* watchUpdateUserData() {
    yield takeEvery(UPDATE_USER_DATA, handleUserDataUpdated);
}

function* checkValidation(reportType) {
    const selectedReportsData = yield select(getQueryDataForType(reportType));
    const userData = yield select(getUserData);
    const { result } = validateQuery(selectedReportsData, userData.report_date_limit?.restrictions);

    if (result) {
        yield put(setActionType(setValidationError(''), reportType));
    }

    yield put(setActionType(setClearSelectionModeOff(), reportType));
}

function* watchFieldClicked() {
    for (let i = 0; i < reportConfigs.length; i++) {
        yield takeLatest(
            checkActionForType(FIELD_CLICKED, reportConfigs[i].type),
            checkValidation,
            reportConfigs[i].type
        );
    }
}

const watchers = [
    watchReportsModeSelected,
    watchDownloadReport,
    _runQueryWatcher(reportTypes.mainReport),
    _runQueryWatcher(reportTypes.pivot),
    _runQueryWatcher(reportTypes.anonymous),
    _runQueryWatcher(reportTypes.fraudProtected),
    _runQueryWatcher(reportTypes.fraudSuspicious),
    _runQueryWatcher(reportTypes.fraudRejected),
    _runQueryWatcher(reportTypes.adMonetization),
    _runQueryWatcher(reportTypes.crossDevice),
    _runQueryWatcher(reportTypes.skan),
    _runQueryWatcher(reportTypes.skanSummary),
    _runQueryWatcher(reportTypes.unifiedReport),
    _runQueryWatcher(reportTypes.creativeExplore),
    watchChartLinesChanged,
    watchTableFilterChange,
    watchDatesChangedGlobal,
    watchGenerateAnonymousLink,
    watchDeleteReport,
    watchShelfClose,
    watchGenerateAnonymousList,
    watchExportTable,
    watchAnonymousSecretIdUpdate,
    watchAnonymousPageRun,
    watchGetAlertsData,
    watchClearToasts,
    watchRunQueryOnFieldClicked,
    watchUpdateChart,
    watchUpdateUserData,
    watchFieldClicked,
];

export { onload, clearReport, destroy, watchers };
