import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from './Settings.css';
import SelectedFields from './SelectedFields';
import SectionsDropdown from './SectionsDropdown';
import MultiFields from './MultiFields';
import Section from './Section';
import { FIELD_SECTION, SELECTED_FIELDS_EXCLUDED_SECTIONS } from './consts';
import SearchArea from './SearchArea';
import { reportsFieldClicked, reportsFieldSet, setActionType } from '../../../actions/reports';
import DimensionsAvailabilityPopover from './DimensionsAvailabilityPopover';
import GlassesIcon from '../../../resources/svg/glasses.svg';
import { getAdminModeEnabled, getPermissionKeys } from '../../../selectors/user';
import Checkbox from '../../atoms/Checkbox';
import {
    extractCheckboxesFieldsFromState,
    getOrderedFields,
    getSectionIndex,
    getSectionPlaceholder,
    isCohortMetricSelected,
    shouldShowField,
} from './utils';
import { useFieldsAvailabilityData } from './hooks';

function Settings({
    allFields,
    selectedFields,
    setSelectedFields,
    reportType,
    adminMode,
    userData,
    translate,
    onCloseShelf,
}) {
    const [searchText, setSearchText] = useState('');
    const [showPopover, setShowPopover] = useState(false);
    const [dimensionInsight, setDimensionInsight] = useState();
    const fieldsAvailabilityData = useFieldsAvailabilityData();
    const currentSelectedFields = useSelector(state => state[reportType].selectedFields);
    const selectedSources = useSelector(state => state[reportType].selectedSources);
    const isCohortSelected = isCohortMetricSelected(selectedFields);
    const dispatch = useDispatch();

    const getFieldsByType = useCallback(
        section => {
            return allFields.filter(field => field.section === section);
        },
        [allFields]
    );

    const dimensionsSections = getFieldsByType(FIELD_SECTION.DIMENSIONS);
    const metricsSections = getFieldsByType(FIELD_SECTION.METRICS);
    const cohortsSections = getFieldsByType(FIELD_SECTION.COHORT_METRICS);
    const eventsSections = getFieldsByType(FIELD_SECTION.EVENTS);
    const cohortPeriodsSections = getFieldsByType(FIELD_SECTION.COHORT_PERIODS);
    const multiFieldsSections = getFieldsByType(FIELD_SECTION.TIME_BREAKDOWNS);
    const checkboxesSections = getFieldsByType(FIELD_SECTION.CHECKBOXES);

    const dimensionsDropdown = useRef(null);

    const handleField = (field, section) => {
        const availableSources = field.available_sources;
        const availableDataTypes = field.available_data_types;
        const selectedDataTypes = currentSelectedFields.dataTypeFields;

        if (availableSources && selectedSources?.length) {
            field.disabled = !availableSources.some(source => selectedSources.includes(source));
        }

        if (availableDataTypes && selectedDataTypes?.length) {
            field.disabled = !availableDataTypes.some(type => selectedDataTypes.includes(type));
        }

        const isSelected = (currentSelectedFields[section.type] || []).includes(field.name);
        const fieldSection = section.section;
        field.section = fieldSection;

        if (isSelected) {
            field.checked = true;

            if (
                !SELECTED_FIELDS_EXCLUDED_SECTIONS.includes(fieldSection) &&
                !selectedFields.map(f => f.name).includes(field.name)
            ) {
                setSelectedFields(getOrderedFields([...selectedFields, field]));
            }
        } else {
            field.checked = false;
        }

        return field;
    };

    const extractFieldsFromState = (state, sections, preventSearch) => {
        const fieldsByCategory = {};

        sections.forEach(section => {
            const fields = (state.fields[section.type] || [])
                .filter(field => {
                    const searchWord = preventSearch ? null : searchText;
                    return shouldShowField(field, searchWord, reportType, adminMode, userData);
                })
                .map(field => {
                    return handleField(field, section);
                });

            fieldsByCategory[translate(section.label)] = fields;
        });

        return fieldsByCategory;
    };

    const permissionKeys = useSelector(state => getPermissionKeys(state));
    const isAdminModeEnabled = useSelector(state => getAdminModeEnabled(state));
    const dimensions = useSelector(state => extractFieldsFromState(state, dimensionsSections));
    const metrics = useSelector(state => extractFieldsFromState(state, metricsSections));
    const cohorts = useSelector(state => extractFieldsFromState(state, cohortsSections));
    const events = useSelector(state => extractFieldsFromState(state, eventsSections));
    const cohortPeriods = useSelector(state => extractFieldsFromState(state, cohortPeriodsSections));
    const multiFields = useSelector(state => extractFieldsFromState(state, multiFieldsSections, true));
    const checkboxesFields = useSelector(state =>
        extractCheckboxesFieldsFromState(state, reportType, checkboxesSections, permissionKeys, isAdminModeEnabled)
    );

    const onFieldRemoved = field => {
        setSelectedFields([...selectedFields.filter(currField => currField !== field)]);
        dispatch(setActionType(reportsFieldClicked(field), reportType));
    };

    const onSelectedField = field => {
        if (!selectedFields.includes(field)) {
            setSelectedFields(getOrderedFields([...selectedFields, field]));
            dispatch(setActionType(reportsFieldClicked(field), reportType));
        } else {
            onFieldRemoved(field);
        }
    };

    const onCheckboxClick = (field, value) => {
        dispatch(setActionType(reportsFieldSet(field, value), reportType));
    };

    const onSelectedMultiGroupField = (groupFields, clickedField) => {
        const currCheckedField = groupFields.find(field => field.checked);

        if (currCheckedField === clickedField) {
            return;
        }
        currCheckedField.checked = false;
        clickedField.checked = true;
        dispatch(setActionType(reportsFieldClicked(currCheckedField), reportType));
        dispatch(setActionType(reportsFieldClicked(clickedField), reportType));
    };

    const onDimensionAvailability = selectedDimension => {
        setDimensionInsight(selectedDimension.name);
        setShowPopover(true);
    };

    const getSectionDropdown = (sectionName, fieldsByCategory) => {
        return Object.keys(fieldsByCategory).length ? (
            <Section index={getSectionIndex(sectionName, selectedFields)} title={sectionName}>
                <SectionsDropdown
                    placeholder={getSectionPlaceholder(sectionName)}
                    searchText={searchText}
                    items={fieldsByCategory}
                    onItemClick={onSelectedField}
                    expanded={!!searchText}
                />
            </Section>
        ) : null;
    };

    return (
        <div className={css.settingsContainer}>
            <div className={css.fieldOptionsContainer}>
                <SearchArea searchingText={searchText} onChange={setSearchText} placeholder="Search Field" />
                <div className={css.sectionsContainer}>
                    <Section
                        title={FIELD_SECTION.DIMENSIONS}
                        index={getSectionIndex(FIELD_SECTION.DIMENSIONS, selectedFields)}
                    >
                        <div ref={dimensionsDropdown}>
                            <SectionsDropdown
                                placeholder={getSectionPlaceholder(FIELD_SECTION.DIMENSIONS)}
                                items={dimensions}
                                searchText={searchText}
                                footerText="+ Add new Custom Dimension"
                                footerOnClick={() => {
                                    window.location.hash = '#/custom-dimensions';
                                    onCloseShelf();
                                }}
                                onItemClick={onSelectedField}
                                expanded={!!searchText}
                                itemActions={[
                                    {
                                        icon: GlassesIcon,
                                        onClick: onDimensionAvailability,
                                        shouldShow: Object.keys(fieldsAvailabilityData).length > 0,
                                    },
                                ]}
                            />
                        </div>
                    </Section>
                    {getSectionDropdown(FIELD_SECTION.METRICS, metrics)}
                    {getSectionDropdown(FIELD_SECTION.COHORT_METRICS, cohorts)}
                    {getSectionDropdown(FIELD_SECTION.EVENTS, events)}
                    {isCohortSelected && getSectionDropdown(FIELD_SECTION.COHORT_PERIODS, cohortPeriods)}
                    <>
                        {Object.entries(multiFields).map(([sectionName, fields]) => {
                            return (
                                <Section
                                    title={sectionName}
                                    key={sectionName}
                                    index={getSectionIndex(sectionName, selectedFields)}
                                >
                                    <MultiFields
                                        items={fields}
                                        onItemClick={field => {
                                            onSelectedMultiGroupField(fields, field);
                                        }}
                                    />
                                </Section>
                            );
                        })}
                    </>
                    {!!checkboxesFields.length && (
                        <Section
                            title={FIELD_SECTION.CHECKBOXES}
                            index={getSectionIndex(FIELD_SECTION.CHECKBOXES, selectedFields)}
                        >
                            {checkboxesFields.map(checkbox => {
                                return (
                                    <Checkbox
                                        style={{ marginBottom: 0 }}
                                        labelClass={css.checkboxLabel}
                                        checked={checkbox.checked}
                                        onChange={() => onCheckboxClick(checkbox.field, !checkbox.checked)}
                                        label={checkbox.label}
                                    />
                                );
                            })}
                        </Section>
                    )}
                </div>
            </div>
            <div className={css.selectedFieldsContainer}>
                <SelectedFields
                    selectedFields={selectedFields}
                    onFieldRemoved={onFieldRemoved}
                    isCohortMetricSelected={isCohortSelected}
                />
            </div>
            {dimensionInsight && (
                <DimensionsAvailabilityPopover
                    dimension={dimensionInsight}
                    fieldsAvailabilityData={fieldsAvailabilityData}
                    anchorEl={dimensionsDropdown.current}
                    showPopover={showPopover}
                    setShowPopover={setShowPopover}
                />
            )}
        </div>
    );
}

Settings.propTypes = {
    allFields: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    selectedFields: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    setSelectedFields: PropTypes.func,
    reportType: PropTypes.string,
    adminMode: PropTypes.bool,
    userData: PropTypes.objectOf(PropTypes.string),
    translate: PropTypes.func,
    onCloseShelf: PropTypes.func,
};

Settings.defaultProps = {
    allFields: [],
    selectedFields: [],
    setSelectedFields: () => {},
    reportType: '',
    adminMode: false,
    userData: {},
    translate: () => {},
    onCloseShelf: () => {},
};

export default withLocalize(Settings);
