import { push } from 'react-router-redux';
import { TOUR_LINK_IDS } from 'utils/consts';
import {
    clearFieldSelection,
    dateChangedGlobal,
    goalSelected,
    isShelfOpen,
    modeSelectedGeneric,
    setActionType,
    tabSelected,
    toggleSlimMode,
} from 'actions/reports';
import { getIsEditShelfOpen, reportDataSelectors } from 'selectors/reports';
import { isGoalsReportPermitted } from 'selectors/permissions';
import { getFieldsForType } from 'selectors/fields';
import { getUserData, isShelfReporting } from 'selectors/user';
import { getDaysDiff, getSecondDatePickerRanges } from 'utils/regional';
import { getDatesText, isFieldSelectionCleared, setReportConfig, unenrichedButton } from 'utils/reports';
import { REPORTS_EVENT_PREFIX, trackMixpanelEvent } from 'utils/general';
import { COMPARE_MODE, REGULAR_MODE } from 'components/pages/reports/consts';

import {
    admonAlignmentToggle,
    getBaseConfig,
    getEmptyStateConfig,
    getExpandedFiltersButton,
    shelfCohortMetrics,
    shelfConversionEvents,
    shelfCrossDeviceDemoCohortType,
    shelfCustomDimensions,
    shelfCustomEvents,
    shelfDimensions,
    shelfTechnicalSpecsDimensions,
    shelfDiscrepancyMetrics,
    shelfEnrichmentDimensions,
    shelfFileCombinerDimensions,
    shelfGoalsForecastMetrics,
    shelfGoalsMetrics,
    shelfGovernanceDimensions,
    shelfGranularityLevels,
    shelfMetadataDimensions,
    shelfCreativeMetadataDimensions,
    shelfMetrics,
    shelfReportingAppMetrics,
    shelfReportingWebMetrics,
    shelfSocialEngagementMetrics,
    shelfSourceAttributionType,
    shelfSourceDimensions,
    shelfTimeBreakdowns,
    shelfUTMParamsFields,
    shelfWithDruidAppend,
    shelfDruidAggregatedDataSourcesMode,
    shelfXorgCohortMetrics,
    shelfXorgMetrics,
    unenrichedToggle,
} from './baseReportConfig';
import { shelfPeriods } from './shelfPeriods';

import PlusIcon from '../../resources/svg/plus_link.svg';
import EraserIcon from '../../resources/icons/eraser.svg';
import EditIconButton from '../../resources/svg/edit_icon_button.svg';
import reportButtonCss from '../../components/widgets/ReportButton.css';
import { getFieldPresetButtons } from './fieldPresets';

// widgets
const Tabs = 'Tabs';
const ActionButton = 'ActionButton';
const Button = 'Button';
const Datepicker = 'Datepicker';
const AddButton = 'AddButton';
const GoalsDropdown = 'GoalsDropdown';
const Label = 'Label';
const WarningMessage = 'WarningMessage';
const Toggle = 'Toggle';

const type = 'reports';
const saveType = 'report';
const path = '/react/reports';

const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.REPORTS', saveType);
setReportConfig(basicConfig, type);

export default {
    ...basicConfig,
    fieldShowDownloadReport: {
        creative_name: true,
        publisher_id: true,
        publisher_site_name: true,
        publisher_site_id: true,
        keyword_id: true,
        keyword: true,
        country_field: true,
        tracker_name: true,
        city_field: true,
        dma_id_field: true,
        dma_name_field: true,
    },
    table: {
        enabled: true,
        show: false,
        drilldownConfig: (state, { requestParams }) => {
            return (
                requestParams.dimensions.includes('source') &&
                state.mode !== COMPARE_MODE &&
                state.selectedTab !== 'goal' &&
                !(
                    requestParams.druid_aggregated_data_sources_mode &&
                    requestParams.druid_aggregated_data_sources_mode.includes('aggregated')
                )
            );
        },
        filteredTotal: { status: null },
    },
    events: {
        load: ['Reports: Page Loaded'],
        urlQuery: ['Reports: Query Loaded From URL'],
    },
    header: {
        text: 'STATIC.PAGE_HEADERS.REPORTS',
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/205046069-Reports-FAQ',
            tourId: TOUR_LINK_IDS.reports,
        },
        emptyStateHeaderData: getEmptyStateConfig('REPORTS'),
        leftComponents: [
            {
                id: 'shelfReportText',
                componentClass: Label,
                selector: state => {
                    const style = { fontSize: '14px', marginBottom: '10px' };
                    const reportData = state[type];

                    const isShowResultTable = !!reportData.reportData;
                    const isShowSpinner = !!reportData.showSpinner;
                    const isShowShelfReporting = isShelfReporting(state, type);
                    let text = 'STATIC.PAGES.REPORTS.SHELF_REPORT_TEXT';

                    if (!isShowShelfReporting) {
                        style.display = 'none';
                    }

                    if (isShowShelfReporting && (isShowResultTable || isShowSpinner)) {
                        style.color = 'var(--blue300)';
                        const selected = reportDataSelectors[type](state);
                        text = getDatesText(selected.reportDates, selected.mode === COMPARE_MODE);
                    }

                    return { style, text };
                },
                actions: [],
            },
            {
                id: 'tabSelection',
                disableSticky: true,
                componentClass: Tabs,
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const isGoalsPermitted = isGoalsReportPermitted(state);
                    let tabs = [];
                    const containerStyle = { padding: '0px', marginTop: '20px', width: '100%' };

                    if (isGoalsPermitted && !isShelfReporting(state, type)) {
                        tabs = [
                            {
                                id: 'regular',
                                label: 'STATIC.BUTTONS.REGULAR_REPORT_TAB',
                            },
                            {
                                id: 'goal',
                                label: 'STATIC.BUTTONS.GOAL_REPORT_TAB',
                            },
                        ];
                    } else {
                        containerStyle.display = 'none';
                    }

                    return {
                        selected: selected.selectedTab,
                        tabs,
                        containerStyle,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: [
                            id => {
                                return setActionType(tabSelected(id), type);
                            },
                            id => {
                                if (id !== 'regular') {
                                    return modeSelectedGeneric(type, 'regular');
                                } else {
                                    return null;
                                }
                            },
                        ],
                    },
                ],
            },
            {
                id: 'date',
                componentClass: Datepicker,
                props: {
                    id: 'headerDatepicker',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const userData = getUserData(state);
                    const containerStyle = {
                        width: 232,
                    };

                    if (selected.selectedTab !== 'regular' || isShelfReporting(state, type)) {
                        containerStyle.display = 'none';
                    }
                    const startDate = selected.reportDates.start_date;
                    const endDate = selected.reportDates.end_date;
                    return {
                        startDate,
                        endDate,
                        dateLimit: userData.report_date_limit?.max_limit,
                        containerStyle,
                        title:
                            selected.mode === COMPARE_MODE
                                ? [
                                      'STATIC.PAGES.REPORTS.DATEPICKER_PERIOD_TITLE',
                                      { daysPeriod: getDaysDiff(startDate, endDate) },
                                  ]
                                : null,
                    };
                },
                actions: [
                    {
                        actionName: 'onApply',
                        action: data => {
                            return setActionType(dateChangedGlobal(type, data), type);
                        },
                    },
                ],
            },
            {
                id: 'vsLabel',
                componentClass: Label,
                props: {
                    text: 'vs',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const style = {
                        margin: '0 10px 5px 10px',
                    };

                    if (
                        selected.selectedTab !== 'regular' ||
                        selected.mode !== COMPARE_MODE ||
                        isShelfReporting(state, type)
                    ) {
                        style.display = 'none';
                    }

                    return {
                        style,
                    };
                },
                actions: [],
            },
            {
                id: 'date2',
                componentClass: Datepicker,
                props: {
                    id: 'headerDatepicker2',
                    dynamic: true,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const containerStyle = {
                        verticalAlign: 'bottom',
                        width: 230,
                    };
                    if (
                        selected.selectedTab !== 'regular' ||
                        selected.mode !== COMPARE_MODE ||
                        isShelfReporting(state, type)
                    ) {
                        containerStyle.display = 'none';
                    }
                    const startDate = selected.reportDates.start_date_2;
                    const endDate = selected.reportDates.end_date_2;
                    let ranges = null;
                    if (selected.reportDates.start_date && selected.reportDates.end_date) {
                        ranges = getSecondDatePickerRanges(
                            selected.reportDates.start_date,
                            selected.reportDates.end_date
                        );
                    }
                    return {
                        ranges,
                        startDate,
                        endDate,
                        containerStyle,
                        title:
                            selected.mode === COMPARE_MODE
                                ? [
                                      'STATIC.PAGES.REPORTS.DATEPICKER_PREVIOUS_PERIOD_TITLE',
                                      { daysPeriod: getDaysDiff(startDate, endDate) },
                                  ]
                                : null,
                    };
                },
                actions: [
                    {
                        actionName: 'onApply',
                        action: data => {
                            const newData = {
                                start_date_2: data.start_date,
                                end_date_2: data.end_date,
                            };
                            return setActionType(dateChangedGlobal(type, newData), type);
                        },
                    },
                ],
            },
            {
                id: 'goalSelection',
                componentClass: GoalsDropdown,
                classType: 'container',
                props: {
                    reportType: type,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const containerStyle = {
                        marginRight: '20px',
                        marginTop: '5px',
                        marginLeft: '0px',
                        verticalAlign: 'bottom',
                        width: '560px',
                    };
                    if (selected.selectedTab !== 'goal') {
                        containerStyle.display = 'none';
                    }
                    return {
                        containerStyle,
                        popperClass: 'goalsDropdownPopper',
                    };
                },
                actions: [
                    {
                        actionName: 'onSelection',
                        action: goal => {
                            return setActionType(goalSelected(goal.name), type);
                        },
                    },
                ],
            },
            {
                id: 'compare',
                componentClass: ActionButton,
                props: {
                    type: 'secondary',
                    level: 'level1',
                    cssModifier: 'compare',
                    IconClass: PlusIcon,
                    tooltipText: 'STATIC.BUTTONS.COMPARE_TWO_PERIODS',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);

                    const containerStyle = {};

                    if (
                        selected.selectedTab !== 'regular' ||
                        selected.mode !== REGULAR_MODE ||
                        isShelfReporting(state, type)
                    ) {
                        containerStyle.display = 'none';
                    }
                    return {
                        containerStyle,
                        disabled: !!selected.disableReportState,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => {
                            return modeSelectedGeneric(type, 'compare');
                        },
                    },
                ],
            },
            {
                id: 'regularMode',
                componentClass: ActionButton,
                props: {
                    text: 'STATIC.BUTTONS.REMOVE',
                    primary: false,
                    cssModifier: 'remove_compare',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const userData = getUserData(state);

                    const containerStyle = {
                        marginRight: userData.show_report_toggle ? '20px' : 0,
                    };

                    if (
                        selected.selectedTab !== 'regular' ||
                        selected.mode !== COMPARE_MODE ||
                        isShelfReporting(state, type)
                    ) {
                        containerStyle.display = 'none';
                    }

                    return {
                        containerStyle,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => {
                            return modeSelectedGeneric(type, 'regular');
                        },
                    },
                ],
            },
            getExpandedFiltersButton(type),
            {
                id: 'createGoal',
                componentClass: AddButton,
                disableSticky: true,
                classType: 'container',
                props: {
                    label: 'STATIC.BUTTONS.CREATE_GOAL',
                    event: {
                        name: 'Reports: Goal: Create Goal',
                    },
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const containerStyle = {
                        verticalAlign: 'bottom',
                        height: '36px',
                        margin: '10px 20px 0px 0px',
                        fontSize: '16px',
                    };
                    if (selected.selectedTab !== 'goal') {
                        containerStyle.display = 'none';
                    }
                    return {
                        containerStyle,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => {
                            return push('/goals/new');
                        },
                    },
                ],
            },
            {
                id: 'warning',
                componentClass: WarningMessage,
                props: {
                    show: false,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const goals = getFieldsForType('goals')(state);
                    let show = false;
                    let message = '';

                    const containerStyle = {
                        display: 'flex',
                        padding: '6px',
                        marginTop: '20px',
                        marginBottom: '15px',
                        width: '604px',
                        borderRadius: '0',
                    };

                    if (selected.mode === COMPARE_MODE) {
                        show =
                            getDaysDiff(selected.reportDates.start_date, selected.reportDates.end_date, 0) !==
                            getDaysDiff(selected.reportDates.start_date_2, selected.reportDates.end_date_2, 0);
                        message = 'STATIC.PAGES.REPORTS.COMPARE_WARNING';
                    }
                    if (selected.selectedTab === 'goal' && selected.selectedGoal) {
                        const selectedGoal = goals.find(cGoal => cGoal.name === selected.selectedGoal);
                        const endDiff = getDaysDiff(selectedGoal.end_date, new Date(), 0);
                        const startDiff = getDaysDiff(selectedGoal.start_date, new Date(), 0);
                        if (endDiff < 0) {
                            show = true;
                            message = 'STATIC.PAGES.REPORTS.GOAL_WARNING';
                            if (startDiff < 0) {
                                message = 'STATIC.PAGES.REPORTS.GOAL_WARNING_2';
                            }
                        }
                    }

                    return {
                        show: isShelfReporting(state, type) ? false : show,
                        message,
                        containerStyle,
                        showIcon: false,
                    };
                },
                actions: [],
            },
            {
                id: 'toggle_slim_mode',
                componentClass: Toggle,
                props: {
                    label: 'STATIC.BUTTONS.ADVANCED_VIEW',
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const userData = getUserData(state);
                    const isShowShelfReporting = isShelfReporting(state, type);

                    return {
                        checked: userData.show_report_toggle && !selected.isSlimMode,
                        style: {
                            display:
                                userData.show_report_toggle &&
                                !isShowShelfReporting &&
                                selected.showFieldsSelection &&
                                selected.selectedTab !== 'goal'
                                    ? 'inline-block'
                                    : 'none',
                            marginLeft: '10px',
                            width: '165px',
                        },
                    };
                },
                actions: [
                    {
                        actionName: 'onToggle',
                        action: ({ target }) => {
                            trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Show More Fields Click', {
                                value: target.checked,
                                report_type: type,
                            });

                            return setActionType(toggleSlimMode(!target.checked), type);
                        },
                    },
                ],
            },
            {
                id: 'clear_selection',
                componentClass: Button,
                props: {
                    text: 'STATIC.BUTTONS.CLEAR_SELECTION',
                    type: 'link',
                    Icon: EraserIcon,
                    iconClass: reportButtonCss.clearButtonIcon,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const { selectedFields, filters: selectedFilters } = state[type];

                    return {
                        style: {
                            display:
                                selected.showFieldsSelection &&
                                selected.selectedTab !== 'goal' &&
                                !isShelfReporting(state, type)
                                    ? 'inline-block'
                                    : 'none',
                            padding: '0 5px',
                        },
                        disabled:
                            isFieldSelectionCleared(selectedFields, selectedFilters) || !!selected.disableReportState,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => {
                            window.mixpanel.track('Clear Report Fields Clicked', { report_type: type });

                            return setActionType(clearFieldSelection(), type);
                        },
                    },
                ],
            },
        ],
        rightComponents: [
            ...basicConfig.header.rightComponents,
            {
                id: 'editShelf',
                componentClass: Button,
                props: {
                    type: 'primary',
                    level: 'level1',
                    style: {
                        marginLeft: '20px',
                        height: '38px',
                        display: 'inline-block',
                        borderRadius: '4px',
                    },
                    Icon: EditIconButton,
                    textClassName: reportButtonCss.editButtonText,
                },
                selector: state => {
                    const selected = reportDataSelectors[type](state);
                    const isEditShelfOpen = getIsEditShelfOpen(state, type);
                    const reportData = state[type];
                    const isShowResultTable = !!reportData.reportData;
                    const isShowSpinner = !!reportData.showSpinner;

                    return {
                        open: selected.showFieldsSelection,
                        hide: !isShelfReporting(state, type),
                        isEditShelfOpen,
                        text: `STATIC.BUTTONS.${isShowResultTable || isShowSpinner ? 'EDIT_REPORT' : 'CREATE_REPORT'}`,
                    };
                },
                actions: [
                    {
                        actionName: 'onClick',
                        action: () => {
                            return setActionType(isShelfOpen(true), type);
                        },
                    },
                ],
            },
            unenrichedButton(type),
            ...getFieldPresetButtons(type),
        ],
    },
    shelf: {
        regular: [
            shelfDimensions,
            shelfGovernanceDimensions,
            shelfCustomDimensions,
            shelfFileCombinerDimensions,
            shelfGranularityLevels,
            shelfEnrichmentDimensions,
            shelfSourceDimensions,
            shelfMetadataDimensions,
            shelfMetrics,
            shelfReportingAppMetrics,
            shelfConversionEvents,
            shelfXorgMetrics,
            shelfDiscrepancyMetrics,
            shelfSocialEngagementMetrics,
            shelfCohortMetrics,
            shelfXorgCohortMetrics,
            shelfCustomEvents,
            shelfPeriods,
            shelfUTMParamsFields,
            shelfReportingWebMetrics,
            shelfTimeBreakdowns,
            shelfSourceAttributionType,
            shelfWithDruidAppend,
            shelfDruidAggregatedDataSourcesMode,
            shelfCrossDeviceDemoCohortType,
            unenrichedToggle,
            admonAlignmentToggle,
            shelfTechnicalSpecsDimensions,
            shelfCreativeMetadataDimensions,
        ],
        goal: [
            shelfGoalsMetrics,
            shelfGoalsForecastMetrics,
            shelfMetrics,
            shelfDiscrepancyMetrics,
            shelfCohortMetrics,
            shelfCustomEvents,
            shelfPeriods,
        ],
        partner: [
            shelfDimensions,
            shelfFileCombinerDimensions,
            shelfGranularityLevels,
            shelfEnrichmentDimensions,
            shelfSourceDimensions,
            shelfMetrics,
            shelfXorgMetrics,
            shelfDiscrepancyMetrics,
            shelfCohortMetrics,
            shelfXorgCohortMetrics,
            shelfCustomEvents,
            shelfConversionEvents,
            shelfPeriods,
            shelfTimeBreakdowns,
            shelfSourceAttributionType,
            shelfWithDruidAppend,
            shelfDruidAggregatedDataSourcesMode,
            unenrichedToggle,
        ],
    },
    path,
};
