import { getAvailableFiltersForType, getCalcFilterForType, reportDataSelectors } from 'selectors/reports';
import {
    clearFieldSelection,
    dateChangedGlobal,
    downloadOrExportReport,
    exportTable,
    filtersExpanded,
    setActionType,
    toggleFieldsAvailability,
    toggleShelf,
} from 'actions/reports';
import { getFiltersAmount, isFieldSelectionCleared, shouldHideEditButton, reportTypes } from 'utils/reports';
import { getFieldsAvailabilityEnabled, isShelfReporting } from 'selectors/user';
import { EmptyStateImageSrc, EmptyStateTasks } from 'components/partials/EmptyState/utils';
import { FIELD_GROUP_HOLDER_DEFAULT_STYLE, FIELD_SECTION } from 'components/pages/reports/consts';
import { REPORTS_EVENT_PREFIX, trackMixpanelEvent } from 'utils/general';
import {
    BOOKMARK_BUTTON_ID,
    DOWNLOAD_CSV_BUTTON_ID,
    DUPLICATE_BOOKMARK_BUTTON_ID,
    EXPORT_TO_GOOGLE_SHEETS_BUTTON_ID,
    FIELDS_AVAILABILITY_BUTTON_ID,
    SHARED_REPORTS_BUTTON_ID,
} from 'utils/reportsConsts';

import React from 'react';
import OneIcon from '../../resources/svg/timeline/one_big.svg';
import TwoIcon from '../../resources/svg/timeline/two_big.svg';
import ThreeIcon from '../../resources/svg/timeline/three_big.svg';
import FourIcon from '../../resources/svg/timeline/four_big.svg';
import ShareIcon from '../../resources/svg/icons/share_icon.svg';
import EraserIcon from '../../resources/icons/eraser.svg';
import SkanPlusIcon from '../../resources/svg/skan_plus/skan.svg';
import GlassesIcon from '../../resources/svg/glasses.svg';
import SaveIcon from '../../resources/svg/icons/save_icon.svg';
import DownloadIcon from '../../resources/icons/download.svg';
import ExportReportIcon from '../../resources/icons/export_report.svg';
import ExportGSheetsIcon from '../../resources/icons/dataConnectors/google_sheets.svg';
import FiltersIcon from '../../resources/svg/filters.svg';
import reportButtonCss from '../../components/widgets/ReportButton.css';
import { GSheetExport } from '../../containers/widgets';
import Icon from '../../components/foundations/Icon';

const RunReportButton = 'RunReportButton';
const Button = 'Button';
const Datepicker = 'Datepicker';
const TopButtons = 'TopButtons';
const Menu = 'Menu';
const SharedReportsShelf = 'SharedReportsShelf';
const BookmarkPopover = 'BookmarkPopover';
const ExpandButton = 'ExpandButton';

const PERMISSION_KEY_UNENRICHED_CHECKBOX = 'display_unenriched_checkbox';
const PERMISSION_KEY_ADMON_ALIGNMENT_CHECKBOX = 'display_admon_alignment_checkbox';
const PERMISSION_KEY_APSALAR_SKAN_SELECTORS = 'display_apsalar_skan_selectors';

export const shelfDimensions = {
    type: 'dimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.DIMENSIONS',
    section: FIELD_SECTION.DIMENSIONS,
};

export const shelfTechnicalSpecsDimensions = {
    type: 'technicalSpecsDimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.TECHNICAL_SPECS_DIMENSIONS',
    section: FIELD_SECTION.TECHNICAL_SPECS_DIMENSIONS,
    bigLabelTooltip: 'STATIC.TOOLTIPS.TECHNICAL_SPECS_DIMENSIONS',
};

export const shelfCreativeMetadataDimensions = {
    type: 'creativeMetadataDimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.CREATIVE_METADATA_DIMENSIONS',
    section: FIELD_SECTION.CREATIVE_METADATA_DIMENSIONS,
};

export const shelfIOSDimensions = {
    type: 'dimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        topSector: {
            numberIcon: ThreeIcon,
            text: 'STATIC.UNIFIED_REPORT_DIMENSIONS_SECTOR_TITLE',
        },
        data: {
            fieldGroupHolderStyle: { ...FIELD_GROUP_HOLDER_DEFAULT_STYLE, borderRadius: '10px' },
            buttonStyle: {
                backgroundColor: 'var(--gray100)',
            },
        },
    },
    section: FIELD_SECTION.DIMENSIONS,
};

export const shelfMetrics = {
    type: 'metrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfIOSNetworkMetrics = {
    type: 'metrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        topSector: {
            numberIcon: TwoIcon,
            text: 'STATIC.UNIFIED_REPORT_NETWORK_METRICS_SECTOR_TITLE',
            tooltip: 'STATIC.TOOLTIPS.UNIFIED_JOIN_PRE_INSTALL_METRICS',
        },
        data: {
            buttonStyle: {
                backgroundColor: 'var(--gray100)',
            },
            fieldGroupHolderStyle: { ...FIELD_GROUP_HOLDER_DEFAULT_STYLE, borderRadius: '10px' },
        },
    },
};

export const shelfDataTypeFields = {
    type: 'dataTypeFields',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        topSector: {
            numberIcon: OneIcon,
            text: 'STATIC.UNIFIED_REPORT_DATA_TYPE_SECTOR_TITLE',
            tooltip: 'STATIC.TOOLTIPS.UNIFIED_DATA_TYPE_VIEW',
        },
        data: {
            buttonStyle: {
                borderRadius: '20px',
                border: 'solid 1px var(--gray500)',
                marginRight: '10px',
            },
            fieldGroupHolderStyle: {
                ...FIELD_GROUP_HOLDER_DEFAULT_STYLE,
                backgroundColor: 'var(--gray100)',
                marginBottom: 'initial',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
            },
        },
    },
};

export const shelfMetricGroupFields = {
    type: 'metricGroupFields',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        data: {
            fieldGroupHolderStyle: {
                ...FIELD_GROUP_HOLDER_DEFAULT_STYLE,
                marginBottom: 'initial',
                borderTop: 'none',
                borderBottom: 'none',
                paddingTop: '5px',
            },
            buttonStyle: {
                backgroundColor: 'var(--gray100)',
            },
            shouldShowFieldGroupWarning: true,
        },
    },
    label: 'STATIC.METRICS',
};

export const shelfCohortMetricGroupFields = {
    type: 'cohortMetricGroupFields',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        data: {
            fieldGroupHolderStyle: {
                ...FIELD_GROUP_HOLDER_DEFAULT_STYLE,
                marginBottom: 'initial',
                borderTop: 'none',
                borderBottom: 'none',
            },
            buttonStyle: {
                backgroundColor: 'var(--gray100)',
            },
        },
    },
    label: 'STATIC.COHORT_METRICS',
};

export const shelfAdMonDAUMetrics = {
    type: 'adMonDAUMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        is_new: true,
        subText: 'STATIC.PAGES.REPORTS.ADMON_DAU_METRICS_SUB_TEXT',
    },
    label: 'STATIC.DAU_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfCrossDeviceAppMetrics = {
    type: 'appMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.APP_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfCrossDeviceWebMetrics = {
    type: 'webMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.WEB_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfUTMParamsFields = {
    type: 'utmParamsFields',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        topBorder: true,
        is_new: true,
        topBorderText: 'STATIC.WEB_MEASUREMENT',
    },
    label: 'STATIC.UTM_PARAMS',
    section: FIELD_SECTION.METRICS,
};

export const shelfReportingWebMetrics = {
    type: 'webMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        is_new: true,
        bottomBorder: true,
    },
    label: 'STATIC.WEB_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfReportingAppMetrics = {
    type: 'appMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        is_new: true,
    },
    label: 'STATIC.APP_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfCrossDeviceMetrics = {
    type: 'crossDeviceMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.CROSS_DEVICE_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfSkanModeledMetrics = {
    type: 'skanModeledMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        skanPlus: {
            topBorderText: 'STATIC.MODELED_SKAN_ADVANCED_ANALYTICS',
            icon: SkanPlusIcon,
        },
    },
    label: 'STATIC.MODELED_SKAN_METRICS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.MODELED_SKAN_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfSocialEngagementMetrics = {
    type: 'socialEngagementMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        is_new: true,
    },
    label: 'STATIC.SOCIAL_ENGAGEMENT_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfSkanModeledCohortMetrics = {
    type: 'skanModeledCohortMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        fontSize: '15px',
        is_new: true,
        bottomBorder: true,
    },
    label: 'STATIC.MODELED_SKAN_COHORT_METRICS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.MODELED_COHORT_SKAN_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfEnrichmentDimensions = {
    type: 'enrichmentDimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.ENRICHMENT_DIMENSIONS',
    section: FIELD_SECTION.DIMENSIONS,
};

export const shelfGovernanceDimensions = {
    type: 'governanceDimensions',
    group: {
        type: 'newTag',
        data: {
            placeholder: 'STATIC.PLACEHOLDERS.SELECT_GOVERNANCE_DIMENSION',
        },
    },
    label: 'STATIC.GOVERNANCE_DIMENSIONS',
    section: FIELD_SECTION.DIMENSIONS,
};

export const shelfCustomDimensions = {
    type: 'custom_dimensions',
    addButton: {
        show: true,
        onClick: () => {
            document.location.hash = '#/custom-dimensions';
        },
        label: 'STATIC.BUTTONS.ADD_NEW_DIMENSIONS',
        event: {
            name: 'Reports: Custom Dimensions: Add New Dimensions',
        },
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.CUSTOM_DIMENSIONS',
    section: FIELD_SECTION.DIMENSIONS,
};

export const unenrichedToggle = {
    type: 'checkbox',
    field: 'unenriched',
    label: 'Show unmapped data',
    bigLabel: 'Unmapped',
    renderChildrenOnly: true,
    bigLabelTooltip: 'STATIC.TOOLTIPS.UNENRICHED',
    permissionKey: PERMISSION_KEY_UNENRICHED_CHECKBOX,
    isShowAdmin: true,
    section: FIELD_SECTION.CHECKBOXES,
};

export const admonAlignmentToggle = {
    type: 'checkbox',
    field: 'admonAlignment',
    label: 'Show unattributed ad revenue data',
    bigLabel: 'Unattributed Ad Revenue',
    renderChildrenOnly: true,
    bigLabelTooltip: 'STATIC.TOOLTIPS.ADMON_ALIGNMENT',
    permissionKey: PERMISSION_KEY_ADMON_ALIGNMENT_CHECKBOX,
    isShowAdmin: true,
    section: FIELD_SECTION.CHECKBOXES,
};

export const shelfTimeBreakdowns = {
    type: 'timeBreakdowns',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
        data: {
            buttonStyle: {
                width: '110px',
            },
        },
    },
    label: 'STATIC.TIME_BREAKDOWN',
    section: FIELD_SECTION.TIME_BREAKDOWNS,
};

export const shelfIOSTimeBreakdowns = {
    type: 'timeBreakdowns',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
        topSector: {
            numberIcon: FourIcon,
            text: 'STATIC.UNIFIED_REPORT_TIME_BREAKDOWN_SECTOR_TITLE',
        },
        data: {
            buttonStyle: {
                width: '110px',
                backgroundColor: 'var(--gray100)',
            },
            fieldGroupHolderStyle: {
                ...FIELD_GROUP_HOLDER_DEFAULT_STYLE,
                marginBottom: 'initial',
                borderRadius: '10px',
            },
        },
    },
    section: FIELD_SECTION.TIME_BREAKDOWNS,
};

export const shelfDateFieldName = {
    type: 'skanDateDimensionName',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
    },
    label: 'STATIC.SKAN_DATE_DIMENSIONS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.DATE_TYPE_DIMENSIONS',
};

export const shelfCohortMetrics = {
    type: 'cohortMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        subText: 'STATIC.PAGES.REPORTS.REQUIRES_COHORT_PERIOD_SUB_TEXT',
    },
    label: 'STATIC.COHORT_METRICS',
    section: FIELD_SECTION.COHORT_METRICS,
};

export const shelfCustomEvents = {
    type: 'customEvents',
    addButton: {
        show: false,
    },
    group: {
        type: 'tag',
        subText: 'STATIC.PAGES.REPORTS.REQUIRES_COHORT_PERIOD_SUB_TEXT',
        data: {
            placeholder: 'STATIC.PLACEHOLDERS.SELECT_EVENT',
        },
    },
    label: 'STATIC.CUSTOM_EVENTS',
    section: FIELD_SECTION.EVENTS,
};

export const shelfSkanCustomEvents = {
    type: 'skanCustomEvents',
    addButton: {
        show: false,
    },
    group: {
        type: 'tag',
        data: {
            placeholder: 'STATIC.PLACEHOLDERS.SELECT_EVENT',
        },
    },
    label: 'STATIC.SKAN_CUSTOM_EVENTS',
};

export const shelfSkanModeledCustomEvents = {
    type: 'skanModeledCustomEvents',
    addButton: {
        show: false,
    },
    group: {
        type: 'tag',
        data: {
            placeholder: 'STATIC.PLACEHOLDERS.SELECT_EVENT',
        },
    },
    label: 'STATIC.MODELED_SKAN_CUSTOM_EVENTS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.MODELED_SKAN_CUSTOM_EVENTS',
    permissionKey: PERMISSION_KEY_APSALAR_SKAN_SELECTORS,
};

export const shelfConversionEvents = {
    type: 'conversionEvents',
    addButton: {
        show: true,
        onClick: () => {
            document.location.hash = '#/singular-events?t=conversion';
        },
        label: 'STATIC.BUTTONS.ADD_NEW_EVENT',
        event: {
            name: 'Reports: Conversion Events: Add New Event',
        },
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.CONVERSION_EVENTS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.CONVERSION_EVENTS',
    section: FIELD_SECTION.METRICS,
};

export const shelfFileCombinerDimensions = {
    type: 'fileCombinerDimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.FILE_COMBINER_DIMENSIONS',
    section: FIELD_SECTION.DIMENSIONS,
};

export const shelfGranularityLevels = {
    type: 'granularityLevels',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.GRANULARITY_LEVELS',
    section: FIELD_SECTION.DIMENSIONS,
};

export const shelfSourceDimensions = {
    type: 'sourceDimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.SOURCE_DIMENSIONS',
    section: FIELD_SECTION.DIMENSIONS,
};

export const shelfMetadataDimensions = {
    type: 'metadataDimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        subText: 'STATIC.PAGES.REPORTS.METADATA_DIMENSION_SUB_TEXT',
    },
    label: 'STATIC.METADATA_DIMENSIONS',
    section: FIELD_SECTION.DIMENSIONS,
};

export const shelfSkanConversionDimensions = {
    type: 'skanConversionDimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.SKAN_CONVERSIONS_DIMENSIONS',
};

export const shelfSkanRedownloadsDimensions = {
    type: 'skan_redownloads_dimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
    },
    label: 'STATIC.SKAN_REDOWNLOADS_DIMENSIONS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.SKAN_REDOWNLOADS_DIMENSIONS',
    checkbox: {
        type: 'checkbox',
        field: 'showRedownloadsDimensionInReport',
        label: 'Show this dimension in report',
        visibleOnParentValue: 'show_both',
    },
};

export const shelfSkanValidatedDimensions = {
    type: 'skan_validated_dimensions',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
    },
    label: 'STATIC.SKAN_VALIDATED_DIMENSIONS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.SKAN_VALIDATED_DIMENSIONS',
    checkbox: {
        type: 'checkbox',
        field: 'showValidatedDimensionInReport',
        label: 'Show this dimension in report',
        visibleOnParentValue: 'show_both',
    },
};

export const shelfConfidenceIntervalFlag = {
    type: 'confidenceIntervalFlag',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
        skanPlusBottomBorder: true,
    },
    label: 'STATIC.SKAN_CONFIDENCE_INTERVAL_FLAG',
    bigLabelTooltip: 'STATIC.TOOLTIPS.SKAN_CONFIDENCE_INTERVAL_FLAG',
    permissionKey: PERMISSION_KEY_APSALAR_SKAN_SELECTORS,
};

export const shelfXorgMetrics = {
    type: 'xorgMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.XORG_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfDiscrepancyMetrics = {
    type: 'discrepancyMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.SOURCE_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfSkanDiscrepancyMetrics = {
    type: 'discrepancyMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.TRACKER_METRICS',
    bigLabelTooltip: 'STATIC.TOOLTIPS.SKAN_TRACKER_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfXorgCohortMetrics = {
    type: 'xorgCohortMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
        subText: 'STATIC.PAGES.REPORTS.REQUIRES_COHORT_PERIOD_SUB_TEXT',
    },
    label: 'STATIC.XORG_COHORT_METRICS',
    section: FIELD_SECTION.COHORT_METRICS,
};

export const shelfSourceAttributionType = {
    type: 'sourceAttributionType',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
        data: {
            buttonStyle: {
                width: '90px',
            },
        },
    },
    label: 'Source Attribution Type',
};

export const shelfWithDruidAppend = {
    type: 'withDruidAppend',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
        data: {
            buttonStyle: {
                width: '200px',
            },
        },
    },
    label: 'With Append Data',
};

export const shelfDruidAggregatedDataSourcesMode = {
    type: 'druidAggregatedDataSourcesMode',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
        data: {
            buttonStyle: {
                width: '250px',
            },
        },
    },
    label: 'Aggregated Druid Data Sources',
};

export const shelfCrossDeviceDemoCohortType = {
    type: 'crossDeviceDemoCohortType',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
        data: {
            buttonStyle: {
                width: '90px',
            },
        },
    },
    label: 'Cohort Type',
};

export const shelfCrossDeviceCohortType = {
    type: 'crossDeviceCohortType',
    addButton: {
        show: false,
    },
    group: {
        type: 'single',
        data: {
            buttonStyle: {
                width: '90px',
            },
        },
        is_new: true,
        subText: 'STATIC.PAGES.REPORTS.CROSS_DEVICE_COHORT_SUB_TEXT',
    },
    label: 'Attribution Type',
};

export const shelfGoalsMetrics = {
    type: 'goalsMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.GOAL_METRICS',
    section: FIELD_SECTION.METRICS,
};

export const shelfGoalsForecastMetrics = {
    type: 'goalsForecastMetrics',
    addButton: {
        show: false,
    },
    group: {
        type: 'multi',
    },
    label: 'STATIC.GOAL_FORECAST',
    section: FIELD_SECTION.METRICS,
};

export const getExpandedFiltersButton = type => ({
    id: 'expandFiltersButton',
    componentClass: ExpandButton,
    props: {
        Icon: FiltersIcon,
    },
    selector: state => {
        const containerStyle = {
            verticalAlign: 'bottom',
            margin: '0px 15px 0px 10px',
        };

        const { filters } = state[type];
        const filtersAmount = getFiltersAmount(filters);
        let text = 'Filters';

        if (filtersAmount) {
            text += ` (${filtersAmount})`;
        }

        const isExpanded = !!state[type]?.isFiltersExpanded;

        const calcFiltersExists = !!getCalcFilterForType(type)(state).filter(filter => !!filter.field).length;
        const availableFiltersExists = !!getAvailableFiltersForType(type)(state).length;

        const filterFieldsExists = calcFiltersExists || availableFiltersExists;

        return {
            text,
            containerStyle,
            isClicked: !!filtersAmount,
            isExpanded,
            hide: isShelfReporting(state, type) || !filterFieldsExists,
        };
    },
    actions: [
        {
            actionName: 'onClick',
            action: isExpanded => {
                trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Filters Click', {
                    isExpanded,
                });
                return setActionType(filtersExpanded(isExpanded), type);
            },
        },
    ],
});

export const getBaseConfig = (type, headerText, saveType) => {
    return {
        type,
        showDownloadReportDatesThreshold: 180,
        fieldShowDownloadReport: {},
        fieldWarnings: {
            sub_publisher_id: {
                msg: 'STATIC.PAGES.REPORTS.PUBLISHER_PERFORMANCE_WARNING',
            },
            sub_publisher_name: {
                msg: 'STATIC.PAGES.REPORTS.PUBLISHER_PERFORMANCE_WARNING',
            },
        },
        header: {
            text: headerText,
            leftComponents: [
                {
                    id: `date_${type}`,
                    componentClass: Datepicker,
                    props: {
                        id: 'headerDatepicker',
                    },
                    selector: state => {
                        const selected = reportDataSelectors[type](state);
                        const startDate = selected.reportDates.start_date;
                        const endDate = selected.reportDates.end_date;
                        return {
                            startDate,
                            endDate,
                        };
                    },
                    actions: [
                        {
                            actionName: 'onApply',
                            action: data => {
                                return setActionType(dateChangedGlobal(type, data), type);
                            },
                        },
                    ],
                },
                getExpandedFiltersButton(type),
                {
                    id: 'clear_selection',
                    componentClass: Button,
                    props: {
                        text: 'STATIC.BUTTONS.CLEAR_SELECTION',
                        type: 'link',
                        Icon: EraserIcon,
                        iconClass: reportButtonCss.clearButtonIcon,
                    },
                    selector: state => {
                        const selected = reportDataSelectors[type](state);
                        const { selectedFields, filters: selectedFilters } = state[type];

                        return {
                            style: {
                                display: selected.showFieldsSelection ? 'inline-block' : 'none',
                            },
                            disabled:
                                isFieldSelectionCleared(selectedFields, selectedFilters) || selected.disableReportState,
                        };
                    },
                    actions: [
                        {
                            actionName: 'onClick',
                            action: () => {
                                window.mixpanel.track('Clear Report Fields Clicked', { report_type: type });

                                return setActionType(clearFieldSelection(), type);
                            },
                        },
                    ],
                },
            ],
            rightComponents: [
                {
                    id: `topButtons_${type}`,
                    componentClass: TopButtons,
                    props: {
                        reportType: type,
                        buttons: [
                            {
                                id: FIELDS_AVAILABILITY_BUTTON_ID,
                                icon: GlassesIcon,
                                label: 'STATIC.PAGES.REPORTS.FIELDS_AVAILABILITY_BUTTON',
                            },
                            {
                                id: SHARED_REPORTS_BUTTON_ID,
                                icon: ShareIcon,
                                disabledByDefault: false,
                                tooltip: 'STATIC.BUTTONS.TOOLTIP.SHARE',
                                reportsWidget: {
                                    componentClass: SharedReportsShelf,
                                    classType: 'container',
                                    props: {
                                        reportType: type,
                                    },
                                },
                            },
                            {
                                id: DUPLICATE_BOOKMARK_BUTTON_ID,
                                icon: props => <Icon name="copy" {...props} />,
                                tooltip: 'STATIC.BUTTONS.DUPLICATE',
                                disableTooltip: 'STATIC.BUTTONS.DUPLICATE',
                                reportsWidget: {
                                    componentClass: BookmarkPopover,
                                    classType: 'container',
                                    props: {
                                        reportType: type,
                                        type: saveType || type,
                                        saveNewCopy: true,
                                        instanceId: 'duplicateBookmark',
                                    },
                                },
                            },
                            {
                                id: BOOKMARK_BUTTON_ID,
                                icon: SaveIcon,
                                disableIfNotCreatedByUser: true,
                                disableTooltip: 'STATIC.BUTTONS.TOOLTIP.DISABLE_SAVE',
                                tooltip: 'STATIC.BUTTONS.BOOKMARK',
                                reportsWidget: {
                                    componentClass: BookmarkPopover,
                                    classType: 'container',
                                    props: {
                                        reportType: type,
                                        type: saveType || type,
                                        instanceId: 'bookmark',
                                    },
                                },
                            },
                        ],
                    },
                    selector: state => {
                        const selected = reportDataSelectors[type](state);
                        const fieldsAvailabilityEnabled = getFieldsAvailabilityEnabled(state, type);

                        const {
                            table: { request = {} },
                        } = selected;
                        const buttonsProps = {
                            bookmark: {
                                disabled: request.is_goals,
                            },
                            copyBookmark: {
                                disabled: !request.bookmark_id,
                            },
                            sharedReports: {
                                disabled: !!selected.disableReportState,
                            },
                            fieldsAvailability: {
                                disabled: !!selected.disableReportState,
                                hide: !fieldsAvailabilityEnabled,
                            },
                        };
                        const bookmarkCreatedByUser =
                            !selected.bookmark.bookmark_creator |
                            (state.user.data.username === selected.bookmark.bookmark_creator);
                        return {
                            disabled: !(selected.table.enabled && selected.table.show),
                            bookmarkCreatedByUser: !!bookmarkCreatedByUser,
                            marked: {
                                bookmark: !!selected.bookmark.bookmark_id,
                            },
                            reportFormHasChanged: selected.reportFormHasChanged,
                            buttonsProps,
                        };
                    },
                    actions: [
                        {
                            actionName: 'onClick',
                            action: id => {
                                if (id === FIELDS_AVAILABILITY_BUTTON_ID) {
                                    return setActionType(toggleFieldsAvailability('', true), type);
                                } else {
                                    return { type: '' };
                                }
                            },
                        },
                    ],
                },
                {
                    id: `menu_${type}`,
                    componentClass: Menu,
                    props: {
                        buttonLabel: 'STATIC.BUTTONS.EXPORT_REPORT',
                        actionIcon: ExportReportIcon,
                        buttonClass: reportButtonCss.exportButton,
                        buttonActiveClass: reportButtonCss.exportButtonActive,
                        buttonIconClass: reportButtonCss.exportButtonIcon,
                        primary: true,
                        listClass: reportButtonCss.exportButtonList,
                        itemClass: reportButtonCss.exportButtonItem,
                        disabledClass: reportButtonCss.exportButtonDisabled,
                        tooltipTitle: 'STATIC.BUTTONS.TOOLTIP.DISABLE_DOWNLOAD',
                        items: [
                            {
                                value: EXPORT_TO_GOOGLE_SHEETS_BUTTON_ID,
                                componentClass: GSheetExport,
                                reportType: type,
                                label: 'STATIC.BUTTONS.EXPORT_TO_GOOGLE_SHEETS',
                                icon: ExportGSheetsIcon,
                                iconStyle: reportButtonCss.exportButtonIcons,
                            },
                            // {
                            //     value: COPY_CSV_TO_CLIPBOARD_BUTTON_ID,
                            //     label: 'STATIC.BUTTONS.COPY_CSV_TO_CLIPBOARD',
                            //     icon: DuplicateIcon,
                            //     iconStyle: reportButtonCss.exportButtonIcons,
                            // },
                            {
                                value: DOWNLOAD_CSV_BUTTON_ID,
                                label: 'STATIC.BUTTONS.DOWNLOAD_CSV',
                                icon: DownloadIcon,
                                iconStyle: reportButtonCss.exportButtonIcons,
                                onClickDispatch: {
                                    dispatchMethod: params =>
                                        type === reportTypes.mainReport
                                            ? setActionType(downloadOrExportReport(...params), type)
                                            : exportTable(type, DOWNLOAD_CSV_BUTTON_ID),
                                    params: [type, type, 'top-bar'],
                                },
                            },
                        ],
                    },
                    selector: state => {
                        const selected = reportDataSelectors[type](state);
                        if (type === reportTypes.mainReport) {
                            return { disabled: false };
                        } else {
                            return {
                                disabled: !(selected.table.enabled && selected.table.show) || selected.showSpinner,
                            };
                        }
                    },
                },
                {
                    id: `edit_${type}`,
                    componentClass: Button,
                    props: {
                        text: 'STATIC.BUTTONS.EDIT_REPORT',
                        type: 'secondary',
                        level: 'level1',
                        style: { minWidth: '103px', marginLeft: '10px' },
                    },
                    selector: state => {
                        const selected = reportDataSelectors[type](state);
                        return {
                            open: selected.showFieldsSelection,
                            hide: shouldHideEditButton(selected) || isShelfReporting(state, type),
                        };
                    },
                    actions: [
                        {
                            actionName: 'onClick',
                            action: e => {
                                return setActionType(toggleShelf(undefined, e), type);
                            },
                        },
                    ],
                },
                {
                    id: `run_${type}`,
                    componentClass: RunReportButton,
                    classType: 'container',
                    props: { reportType: type, style: { marginLeft: '10px' } },
                    selector: state => {
                        return {
                            hide: isShelfReporting(state, type),
                        };
                    },
                },
            ],
        },
        table: {
            enabled: true,
            show: false,
            filteredTotal: { status: null },
        },
        chart: {
            enabled: true,
            show: false,
            lines: 5,
            showLegend: true,
            slowLoading: false,
        },
    };
};

export const getEmptyStateConfig = pageName => {
    return {
        titleText: 'STATIC.EMPTY_STATE.TITLE',
        subTitleText: `STATIC.EMPTY_STATE.SUB_TITLE.${pageName}`,
        imageSrc: EmptyStateImageSrc.INTRO_SINGULAR_PIPELINE,
        tasks: [
            {
                topic: EmptyStateTasks.SDK_INTEGRATED,
                name: 'STATIC.EMPTY_STATE.TASKS.SDK_TASK',
                goRedirect: '#/react/sdk_integration',
            },
            {
                topic: EmptyStateTasks.DATA_CONNECTOR_ADDED,
                name: 'STATIC.EMPTY_STATE.TASKS.DATA_CONNECTORS_TASK',
                goRedirect: '#/react/data-sources',
            },
        ],
    };
};
