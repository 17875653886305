import { CUSTOMER_TIERS } from '../../utils/consts';

function checkFeatureFlag(field, userData) {
    const neededFeatureFlag = field.feature_flag_visible;
    return !!userData.feature_flags?.includes(neededFeatureFlag);
}

const slimViewRelevantFieldTypes = [
    'dimensions',
    'metrics',
    'metadataDimensions',
    'cohortMetrics',
    'custom_dimensions',
    'sourceDimensions',
    'discrepancyMetrics',
];

const standardAnalyticsRelevantFieldTypes = [
    'dimensions',
    'metrics',
    'cohortMetrics',
    'dimension',
    'metadataDimensions',
    'custom_dimensions',
    'sourceDimensions',
    'timeBreakdowns',
    'conversionEvents',
];

const analyticsOnlySkanBlacklistedFieldTypes = ['skanDateDimensionName', 'skan_validated_dimensions'];

const analyticsOnlySkanBlacklistedFieldNames = ['skan_conversion_values_count', 'skan_conversion_values_ratio'];

export const filterFieldsByFlags = (field, isSlimMode, isStandardAnalytics) => {
    if (isStandardAnalytics) {
        const fieldType = field.actual_type ? field.actual_type : field.type;
        return standardAnalyticsRelevantFieldTypes.includes(fieldType);
    }

    if (isSlimMode) {
        return !slimViewRelevantFieldTypes.includes(field.actual_type) || field.slim_mode_visible;
    }

    return true;
};

const isVisibleField = field => !('visible' in field) || field.visible;

const isAdminVisibleField = (field, adminModeEnabled) => adminModeEnabled || !field.only_admin_visible;

const governanceVisibilityRules = (field, adminModeEnabled, userData) => {
    const isGovernance = field.actual_type === 'governanceDimensions';
    if (!isGovernance) {
        // rule should apply to governance fields only
        return true;
    }

    // campaign reports should only display campaign schemas' fields, unless explicitly enabled
    const isCreative = field.category.startsWith('Creative');
    const canShowMismatchedFields = !!userData.feature_flags?.includes('governance_display_mismatch_type_fields');
    return adminModeEnabled || canShowMismatchedFields || !isCreative;
};

const reportVisibilityRules = (field, adminModeEnabled, userData = {}, isSlimMode = false) => {
    const isStandardAnalytics = userData.organization_tier === CUSTOMER_TIERS.STANDARD_ANALYTICS;
    const visibilityKey = isStandardAnalytics && !adminModeEnabled ? 'standard_analytics_visible' : 'visible';
    return (
        (!(visibilityKey in field) || field[visibilityKey] === true || checkFeatureFlag(field, userData)) &&
        ((field.only_admin_visible && adminModeEnabled) || !field.only_admin_visible) &&
        filterFieldsByFlags(field, isSlimMode, isStandardAnalytics) &&
        governanceVisibilityRules(field, adminModeEnabled, userData)
    );
};

const creativesVisibilityRules = (field, adminModeEnabled = {}) => {
    let isVisible;

    if (['dimensions', 'dimension'].includes(field.type)) {
        isVisible = field.creative_visible;
    } else {
        isVisible = !('visible' in field) || (field.visible && !field.fraud_only_visible);
    }

    return isVisible && isAdminVisibleField(field, adminModeEnabled);
};

export default {
    reports: reportVisibilityRules,
    pivot: reportVisibilityRules,
    creatives: creativesVisibilityRules,
    creativeExplore: creativesVisibilityRules,
    anonymousReport: (dim, adminModeEnabled, userData = {}) => {
        return (
            (isVisibleField(dim) || checkFeatureFlag(dim, userData)) &&
            ((dim.only_admin_visible && adminModeEnabled) || !dim.only_admin_visible)
        );
    },
    permissions: dim => {
        return (
            (dim.visible === true ||
                dim.creative_visible ||
                dim.ad_monetization_visible ||
                dim.fraud_only_visible ||
                dim.fraud_protected_visible ||
                dim.fraud_rejected_visible ||
                dim.fraud_suspicious_visible ||
                dim.skan_visible ||
                dim.skan_summary_visible ||
                dim.unified_report_visible ||
                dim.cross_device_visible) &&
            !dim.only_admin_visible
        );
    },
    fraudProtected: (field, adminModeEnabled) => {
        let isVisible;

        if (field.type === 'metric' && field.actual_type === 'cohortMetrics') {
            isVisible = isVisibleField(field) && !field.xorg_only;
        } else if (field.type === 'dimension' || field.type === 'metric') {
            isVisible = field.fraud_protected_visible;
        } else {
            isVisible = isVisibleField(field);
        }

        return isVisible && isAdminVisibleField(field, adminModeEnabled);
    },
    fraudSuspicious: (field, adminModeEnabled) => {
        let isVisible;

        if (field.type === 'metric' && field.actual_type === 'cohortMetrics') {
            isVisible = isVisibleField(field) && !field.xorg_only;
        } else if (field.type === 'dimension' || field.type === 'metric') {
            isVisible = field.fraud_suspicious_visible;
        } else {
            isVisible = isVisibleField(field);
        }

        return isVisible && isAdminVisibleField(field, adminModeEnabled);
    },
    fraudRejected: (field, adminModeEnabled) => {
        let isVisible;

        if (field.type === 'dimension' || field.type === 'metric') {
            isVisible = field.fraud_rejected_visible;
        } else {
            isVisible = isVisibleField(field);
        }

        return isVisible && isAdminVisibleField(field, adminModeEnabled);
    },
    adMonetization: (field, adminModeEnabled) => {
        let isVisible;

        if (
            field.type === 'dimension' ||
            field.type === 'metric' ||
            field.actual_type === 'periods' ||
            field.actual_type === 'cohortMetrics'
        ) {
            isVisible = field.ad_monetization_visible;
        } else {
            isVisible = isVisibleField(field);
        }

        return isVisible && isAdminVisibleField(field, adminModeEnabled);
    },
    crossDevice: (field, adminModeEnabled) => {
        let isVisible;

        if (field.actual_type === 'dimensions' || field.actual_type === 'metrics' || field.cross_device_visible) {
            isVisible = field.cross_device_visible;
        } else {
            isVisible = isVisibleField(field);
        }

        return isVisible && isAdminVisibleField(field, adminModeEnabled);
    },
    skan: (field, adminModeEnabled) => {
        let isVisible;

        if (field.type === 'dimension' || field.type === 'metric') {
            isVisible = field.skan_visible;
        } else {
            isVisible = !('skan_visible' in field) || field.skan_visible;
        }

        return isVisible && isAdminVisibleField(field, adminModeEnabled);
    },
    skanSummary: (field, adminModeEnabled, userData = {}) => {
        let isVisible;

        if (
            !userData.attribution_enabled &&
            (analyticsOnlySkanBlacklistedFieldTypes.includes(field.actual_type) ||
                analyticsOnlySkanBlacklistedFieldNames.includes(field.name))
        ) {
            isVisible = false;
        } else if (field.type === 'dimension' || field.type === 'metric') {
            isVisible = field.skan_summary_visible;
        } else {
            isVisible = !('skan_summary_visible' in field) || field.skan_summary_visible;
        }

        return isVisible && isAdminVisibleField(field, adminModeEnabled);
    },
    unifiedReport: (field, adminModeEnabled) => {
        let isVisible;

        if (field.type === 'dimension' || field.type === 'metric' || field.unified_report_visible) {
            isVisible = field.unified_report_visible;
        } else {
            isVisible = isVisibleField(field);
        }

        return isVisible && isAdminVisibleField(field, adminModeEnabled);
    },
};
