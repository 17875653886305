import Reports from './reports';
import API from './api';
import { defaultReportQueryParams } from '../utils/reports';

const api = new API();

const URL_CREATIVE_EXPLORE_DATA = '/api/get_creative_explore_data';

let instance = null;

class CreativeExplore {
    constructor() {
        if (!instance) {
            instance = this;
        }

        return instance;
    }

    runQuery(params, options) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_CREATIVE_EXPLORE_DATA, {
                method: 'POST',
                credentials: 'include',
                body: {
                    ...defaultReportQueryParams,
                    ...params,
                },
                cache: false,
                unique: true,
                ...options,
            })
                .then(response => {
                    if (response.status !== 0) {
                        reject(Reports.ERROR_RUN_QUERY);
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }
}

export default CreativeExplore;
