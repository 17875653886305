import { ROUTE_PREFIX } from './global/utils';
import AudienceSegmentsContainer from './audienceSegments/container';
import AthenaOverRealtimeContainer from './athenaOverRealtime/container';
import DashboardContainer from './customDashboards/dashboardContainer';
import AudienceDistributionsContainer from './audienceDistributions/container';
import GovernanceSchemas from './governance/schemas/page';
import ManageCodes from './governance/codes/page';
import EventsUpload from './upload_events/manageEventsUploads';
import Usage from './usage/usage';
import LinkManagementContainer from './linkManagement/container';
import PartnersLandingPage from './partnersLandingPage/container';
import DataSourcesContainer from './dataSources/container';
import ApiKeys from './apiKeys/page';

import HomepageContainer from './homepage/homepageContainer';
import SignupContainer from './signup/signupContainer';
import AttributionLandingPage from './attributionLandingPage/container';
import AlertsContainer from './alerts/container';
import LoadingPageContainer from './loadingPage/container';
import WebTrackingLinksContainer from './webTrackingLinks/container';

const pageConfigs = [
    {
        path: '/home',
        saga: 'homepage',
        container: HomepageContainer,
    },
    {
        path: '/signup',
        saga: 'signup',
        container: SignupContainer,
    },
    {
        path: '/custom-dimensions',
        saga: 'customDimensions',
        containerName: 'CustomDimensions',
    },
    {
        path: '/team',
        saga: 'teamManagement',
        containerName: 'TeamManagement',
    },
    {
        path: '/attribution/create-smart-link',
        componentName: 'CreateSmartLink',
        saga: 'createSmartLink',
    },
    {
        path: '/singular-events',
        containerName: 'CustomEvents',
        saga: 'customEvents',
    },
    {
        path: '/reports',
        saga: 'reports',
    },
    {
        path: '/pivot',
        saga: 'reports',
    },
    {
        path: '/web-tracking/create-link',
        container: WebTrackingLinksContainer,
        saga: 'webTrackingLinks',
    },
    {
        path: '/dashboard',
        container: DashboardContainer,
    },
    {
        path: '/governance/schemas',
        container: GovernanceSchemas,
    },
    {
        path: '/governance/manage-codes',
        container: ManageCodes,
    },
    {
        path: '/upload_events/manage-uploads',
        container: EventsUpload,
    },
    {
        path: '/audiences/segments',
        container: AudienceSegmentsContainer,
        saga: 'audienceSegments',
    },
    {
        path: '/audiences/distributions',
        container: AudienceDistributionsContainer,
        saga: 'audienceDistributions',
    },
    {
        path: '/audiences/ab_testing',
        componentName: 'ABTesting',
    },
    {
        path: '/attribution/view-links',
        containerName: 'TrackingLinks',
        saga: 'trackingLinks',
    },
    {
        path: '/benchmarks/market-share-of-voice',
        componentName: 'BenchmarksMarketShare',
    },
    {
        path: '/benchmarks/market-share-vs-me',
        componentName: 'BenchmarksMarketShareVsMe',
    },
    {
        path: '/benchmarks/network-optimizations',
        componentName: 'BenchmarksNetworkOptimizations',
    },
    {
        path: '/fraud/protected',
        saga: 'reports',
    },
    {
        path: '/fraud/suspicious',
        saga: 'reports',
    },
    {
        path: '/fraud/rejected',
        saga: 'reports',
    },
    {
        path: '/admonetization',
        saga: 'reports',
    },
    {
        path: '/cross-device',
        saga: 'reports',
    },
    {
        path: '/skadnetwork_report',
        saga: 'reports',
    },
    {
        path: '/skadnetwork_summary_report',
        saga: 'reports',
    },
    {
        path: '/unified_report',
        saga: 'reports',
    },
    {
        path: '/realtime',
        container: AthenaOverRealtimeContainer,
        saga: 'athenaOverRealtime',
    },
    {
        path: '/link-management',
        container: LinkManagementContainer,
        saga: 'linkManagement',
    },
    {
        path: '/partners',
        container: PartnersLandingPage,
        saga: 'partnersLandingPage',
    },
    {
        path: '/data-sources',
        container: DataSourcesContainer,
        saga: 'dataSources',
    },
    {
        path: '/data-destinations',
        componentName: 'DataDestinationsPage',
        saga: 'dataSources',
    },
    {
        path: '/api',
        container: ApiKeys,
    },
    {
        path: '/sdk_integration',
        componentName: 'SDKPage',
    },
    {
        path: '/usage',
        container: Usage,
    },
    {
        path: '/skadnetwork',
        componentName: 'SKAdNetwork',
    },
    {
        path: '/attribution',
        container: AttributionLandingPage,
    },
    {
        path: '/alerts',
        container: AlertsContainer,
    },
    {
        path: '/media-mix-modeling',
        componentName: 'MediaMixModeling',
    },
    {
        path: '/loading_page',
        container: LoadingPageContainer,
    },
    {
        path: '/banners',
        componentName: 'Banners',
        saga: 'banners',
    },
    {
        path: '/testing-console',
        componentName: 'TestingConsole',
        saga: 'testingConsole',
        mixpanelComponentName: 'Testing Console',
    },
    {
        path: '/account-plan',
        componentName: 'OrganizationPlan',
    },
    {
        path: '/plan-upgrade',
        componentName: 'CheckoutPage',
        mixpanelComponentName: 'Account Plan - plan upgrade checkout',
    },
    {
        path: '/quick-reports/campaign',
        componentName: 'CampaignQuickReport',
        mixpanelComponentName: 'Campaign Report',
    },
    {
        path: '/nike-social-links',
        componentName: 'NikeSocialLinks',
    },
    {
        path: '/mta',
        componentName: 'MTA',
        saga: 'mta',
    },
    {
        path: '/partner-config',
        componentName: 'PartnerConfiguration',
    },
    {
        path: '/assisted-installs',
        componentName: 'AssistedInstalls',
        saga: 'assistedInstalls',
    },
    {
        path: '/creative-gallery',
        componentName: 'TagsVisibility',
    },
    {
        path: '/creative-explore',
        saga: 'reports',
    },
];

const getReactPath = path => {
    return `${ROUTE_PREFIX}${path}`;
};

export const REACT_ROUTES_SET = new Set(pageConfigs.map(pageConfig => getReactPath(pageConfig.path)));

export default [
    {
        path: '/',
        componentName: 'Default',
    },
    ...pageConfigs.map(pageConfig => ({ ...pageConfig, path: getReactPath(pageConfig.path) })),
];
