import moment from 'moment/moment';
import { DataTypes, WIDGET_DATA_TYPES } from './widgetDataTypes';
import { sortAlphabeticallyLowecase } from '../utils/sortUtil';
import { trackMixpanelEvent } from '../utils/general';
import { IGNORED_DATA_TYPES, SKAN_DATE_DIMENSION_NAMES } from './consts';

export const METRIC_COHORT_SEPARATOR = '__';
export const METRIC_COHORT_SINGLE_SEPARATOR = '_';
export const FIELD_NAME_SEPARATOR = '.';
export const UNIFIED_FILED_NAME_SEPARATOR = '_';
export const COMPARE_SEPARATOR = '__';

// Enum of request params keys to be used as a separate field in the report request.
export const requestParams = Object.freeze({
    MODELED_SKAN_EVENTS: 'modeled_skan_events',
    UNIFIED_METRIC_GROUP: 'unified_metric_group',
    UNIFIED_DATA_TYPE: 'unified_data_type',
    UNIFIED_COHORT_METRICS: 'unified_cohort_metrics',
    CROSS_DEVICE_COHORT_TYPE: 'cross_device_cohort_type',
    SKAN_DATE_TYPES: 'skan_data_types',
});

// A mapping between the key to the param name in the report request.
export const requestParamsMapping = {
    [requestParams.MODELED_SKAN_EVENTS]: 'modeled_skan_custom_events',
    [requestParams.UNIFIED_METRIC_GROUP]: 'metric_group_fields',
    [requestParams.UNIFIED_DATA_TYPE]: 'data_type_fields',
    [requestParams.UNIFIED_COHORT_METRICS]: 'cohort_metric_group_fields',
    [requestParams.CROSS_DEVICE_COHORT_TYPE]: 'cross_device_cohort_type',
    [requestParams.SKAN_DATE_TYPES]: 'skan_date_dimension_name',
};

export const TIME_BREAKDOWN_PSEUDO_DIMENSIONS = [
    { name: '__day', display_name: 'Day', visible: true },
    { name: '__week', display_name: 'Week', visible: true },
    { name: '__month', display_name: 'Month', visible: true },
];

export const DEFAULT_WIDGET_TYPE = 'column';
export const DEFAULT_WIDGET_DATA_TYPE = WIDGET_DATA_TYPES[DataTypes.REPORTS].name;
export const DEFAULT_SKAN_DATE_TYPE = SKAN_DATE_DIMENSION_NAMES.POSTBACK_DATE;

export const WIDGET_CHANGE_TYPES = {
    LAYOUT: 'layout',
    DELETED: 'deleted',
    EDITED: 'edited',
    CLONED: 'cloned',
    ADDED: 'added',
    TABLE_LAYOUT: 'table_layout',
};

export const API_VERSIONS = {
    VERSION_1: 0,
    VERSION_2: 1,
};

export const trackDashboardsMixpanelEvent = (eventName, params) => {
    trackMixpanelEvent('Dashboards 2.0', eventName, params);
};

function fieldsToMixpanelList(values) {
    if (!values) {
        return null;
    }

    // Dedupe duplicate values
    const dedupedValues = Array.from(new Set(values));

    // Sort to limit cardinality
    dedupedValues.sort(sortAlphabeticallyLowecase);
    return dedupedValues.join(',');
}

export const trackDashboardsWidgetUpdateMixpanelEvent = ({ dashboard, widget, changeType }) => {
    const { type, gridParams, options, query, dataType } = widget;
    // We consider both cloned and added as added widget so we merge them as one
    let eventName = changeType === WIDGET_CHANGE_TYPES.CLONED ? WIDGET_CHANGE_TYPES.ADDED : changeType;
    eventName = eventName.charAt(0).toUpperCase() + eventName.slice(1);
    eventName = `${eventName} Widget`;
    const params = {
        widget_id: gridParams.i,
        widget_type: type,
        widget_data_type: dataType,
        change_type: changeType,
        dashboard_name: dashboard?.name,
        widget_metrics: fieldsToMixpanelList(query.metrics),
        widget_dimensions: fieldsToMixpanelList([...query.dimensions, ...options.rows, ...options.columns]),
        widget_filter: fieldsToMixpanelList(query.filters.map(f => f.field)),
    };
    if (changeType in [WIDGET_CHANGE_TYPES.CLONED, WIDGET_CHANGE_TYPES.ADDED]) {
        params.is_cloned = changeType === WIDGET_CHANGE_TYPES.CLONED;
    }
    trackDashboardsMixpanelEvent(eventName, params);
};

export const trackDashboardsWidgetOpenedMixpanelEvent = ({ dashboard, widget, changeType, isSaved }) => {
    const { type, gridParams, options, query, dataType } = widget;
    const dashboardName = dashboard?.name;

    trackDashboardsMixpanelEvent('Shelf Opened', {
        widget_id: gridParams.i,
        widget_type: type,
        change_type: changeType,
        widget_data_type: dataType,
        dashboard_name: dashboardName,
        widget_metrics: fieldsToMixpanelList(query.metrics),
        widget_dimensions: fieldsToMixpanelList([...query.dimensions]),
        widget_rows: fieldsToMixpanelList([...options.rows]),
        widget_columns: fieldsToMixpanelList([...options.columns]),
        widget_filter: fieldsToMixpanelList(query.filters.map(f => f.field)),
        is_saved: isSaved,
    });
};

export function isTimeDimension(dimension) {
    return TIME_BREAKDOWN_PSEUDO_DIMENSIONS.find(tb => tb.name === dimension);
}

export function renameTimebreakdownDimension(fieldsList, dataTypeName) {
    return fieldsList.map(field => {
        if (isTimeDimension(field)) {
            if (dataTypeName === DataTypes.SKAN) {
                return SKAN_DATE_DIMENSION_NAMES.POSTBACK_DATE;
            } else if (dataTypeName === DataTypes.UNIFIED) {
                return SKAN_DATE_DIMENSION_NAMES.ESTIMATED_INSTALL_DATE;
            }
            return 'date_field';
        } else {
            return field;
        }
    });
}

function __omitTypename(key, value) {
    return key === '__typename' ? undefined : value;
}

export function omitTypename(obj) {
    return JSON.parse(JSON.stringify(obj, __omitTypename));
}

function getCohorMetric(metricName) {
    return metricName
        .split(METRIC_COHORT_SEPARATOR)
        .slice(0, -1)
        .join(METRIC_COHORT_SEPARATOR);
}

export function getCohortPeriod(metricName) {
    if (!metricName.includes(METRIC_COHORT_SEPARATOR)) {
        return null;
    }

    const metricParts = metricName.split(METRIC_COHORT_SEPARATOR);
    return metricParts[metricParts.length - 1];
}

function addAdditionalParamField(additionalParams, paramName, field) {
    const paramKey = requestParamsMapping[paramName];
    if (additionalParams[paramKey]) {
        additionalParams[paramKey].push(field);
    } else {
        additionalParams[paramKey] = [field];
    }
}

function handleAdditionalParamField(additionalParams, field) {
    const fieldParts = field.split(FIELD_NAME_SEPARATOR);

    if (fieldParts.length === 1) {
        return true;
    } else {
        const paramName = fieldParts[0];
        const fieldName = fieldParts[1];

        addAdditionalParamField(additionalParams, paramName, fieldName);
        return false;
    }
}

export function buildQueryObject(
    query,
    globalFilters = [],
    startDate,
    endDate,
    displayUnenriched,
    reportType,
    compare,
    startDate2,
    endDate2,
    dataTypeFields,
    dataTypeName
) {
    const timeBreakdownDimensions = query.dimensions.filter(x =>
        TIME_BREAKDOWN_PSEUDO_DIMENSIONS.find(tb => tb.name === x)
    );

    const additionalParams = {};

    // Transform query into a query object
    const cohortMetrics = query.metrics.filter(x => x.includes(METRIC_COHORT_SEPARATOR));
    const queryObject = {
        time_breakdown: timeBreakdownDimensions.length === 0 ? 'all' : timeBreakdownDimensions[0].slice(2),
        start_date: startDate,
        end_date: endDate,
        dimensions: query.dimensions.filter(dim => {
            if (timeBreakdownDimensions.includes(dim)) {
                return false;
            }

            return handleAdditionalParamField(additionalParams, dim);
        }),
        metrics: query.metrics.filter(metric => {
            if (metric.includes(METRIC_COHORT_SEPARATOR)) {
                return false;
            }

            return handleAdditionalParamField(additionalParams, metric);
        }),
        [dataTypeName === DataTypes.UNIFIED ? 'cohort_metric_group_fields' : 'cohort_metrics']: cohortMetrics
            .map(getCohorMetric)
            .filter(metric => {
                return handleAdditionalParamField(additionalParams, metric);
            }),
        [dataTypeName === DataTypes.UNIFIED ? 'unified_cohort_periods' : 'cohort_periods']: [
            ...new Set(cohortMetrics.map(getCohortPeriod)),
        ],
        filters: [...query.filters, ...globalFilters].map(({ field: dimension, operator, values, options }) => ({
            dimension,
            operator,
            values,
            options,
        })),
        display_unenriched: displayUnenriched,
        query_type: reportType,
        is_dashboard: true,
        skan_date_dimension_name: query.skanDateDimensionName,
        data_type_fields: query.dataTypeFields.filter(metric => {
            return handleAdditionalParamField(additionalParams, metric);
        }),
        ...dataTypeFields,
        ...additionalParams,
    };

    if (compare && startDate2 !== null && endDate2 !== null) {
        queryObject.compare = true;
        queryObject.start_date_2 = startDate2;
        queryObject.end_date_2 = endDate2;
    }

    if (dataTypeName === DataTypes.CREATIVES) {
        queryObject.api_version = API_VERSIONS.VERSION_2;
    }

    return queryObject;
}

const CLONED_DASHBOARD_NAME_REGEX = /^(.+) \((\d+)\)$/;

export function getUniqueName(objectsList, namePrefix) {
    let name = namePrefix;
    let count = 1;

    // Get rid of pre-existing count
    const clonedMatch = name.match(CLONED_DASHBOARD_NAME_REGEX);

    if (clonedMatch) {
        count = parseInt(clonedMatch[2], 10) + 1;
        name = `${clonedMatch[1]} (${count})`;
    }

    while (objectsList.some(item => item.name === name)) {
        name = `${namePrefix} (${count})`;
        ++count;
    }

    return name;
}

export function updateWidgetsWithNewLayout(dashboard, widgets, newGridLayout) {
    return widgets.map(widget => {
        const newLayoutForWidget = newGridLayout.find(l => l.i === widget.gridParams.i);
        if (!newLayoutForWidget) return widget;

        const { x, y, w, h } = newLayoutForWidget;
        const { w: oldWidth, h: oldHeight } = widget.gridParams;
        const eventName = w === oldWidth && h === oldHeight ? 'Widget Moved' : 'Widget Resized';

        trackDashboardsMixpanelEvent(eventName, {
            dashboard_name: dashboard?.name,
            widget_id: widget.gridParams.i,
            widget_type: widget.type,
            widget_data_type: widget.dataType,
        });

        return {
            ...widget,
            gridParams: {
                ...widget.gridParams,
                x,
                y,
                w,
                h,
            },
        };
    });
}

export const dashboardPageName = 'DASHBOARD';

export const last7Days = moment()
    .subtract(6, 'days')
    .format('YYYY-MM-DD');

// extract the unified type from the data type field
// for example, extract "unified" from "unified_data_type" or "revenue" from "revenue_metric_group"
export function extractUnifiedType(dataTypeField) {
    return dataTypeField
        .split(FIELD_NAME_SEPARATOR)
        .slice(-1)[0]
        .split(METRIC_COHORT_SEPARATOR)[0];
}

export function shouldUseDataType(dataTypeField) {
    const dataTypeOptionName = dataTypeField.split(FIELD_NAME_SEPARATOR)[0];
    return !IGNORED_DATA_TYPES.includes(dataTypeOptionName);
}
