export const FIELD_SECTION = {
    FILTERS: 'Filters',
    DIMENSIONS: 'Dimensions',
    TECHNICAL_SPECS_DIMENSIONS: 'Technical Specs Dimensions',
    CREATIVE_METADATA_DIMENSIONS: 'Creative Metadata Dimensions',
    METRICS: 'Metrics',
    COHORT_METRICS: 'Cohort Metrics',
    EVENTS: 'Events',
    COHORT_PERIODS: 'Cohort Periods',
    TIME_BREAKDOWNS: 'Time Breakdown',
    CHECKBOXES: 'Options',
};

export const ANGULAR_ADMIN_MODE_CHANGED_EVENT = 'angularAdminModeChanged';

export const REGULAR_MODE = 'regular';
export const COMPARE_MODE = 'compare';

export const FIELDS_BY_ORDER = [
    FIELD_SECTION.DIMENSIONS,
    FIELD_SECTION.METRICS,
    FIELD_SECTION.COHORT_METRICS,
    FIELD_SECTION.EVENTS,
    FIELD_SECTION.COHORT_PERIODS,
];

export const SECTIONS_BY_ORDER = [
    FIELD_SECTION.FILTERS,
    FIELD_SECTION.DIMENSIONS,
    FIELD_SECTION.TECHNICAL_SPECS_DIMENSIONS,
    FIELD_SECTION.METRICS,
    FIELD_SECTION.COHORT_METRICS,
    FIELD_SECTION.EVENTS,
    FIELD_SECTION.COHORT_PERIODS,
    FIELD_SECTION.TIME_BREAKDOWNS,
    FIELD_SECTION.CHECKBOXES,
];

export const SELECTED_FIELDS_EXCLUDED_SECTIONS = [FIELD_SECTION.TIME_BREAKDOWNS, FIELD_SECTION.CHECKBOXES];

export const TOTALS_STATUS = {
    PENDING: 'PENDING',
    DONE: 'DONE',
};

const UNIFIED_DISPLAY_NAME = 'Unified';
const TRACKER_DISPLAY_NAME = 'Tracker';
const SKAN_DISPLAY_NAME = 'SKAN';
const NETWORK_DISPLAY_NAME = 'Network';

export const UNIFIED_REPORT_TYPES = Object.freeze({
    PRE_INSTALL: 'pre_install',
    UNIFIED: 'unified',
    TRACKER: 'tracker',
    SKAN: 'skan',
    NETWORK: 'network',
});
export const SORTING_INDEX_MAP = {
    [UNIFIED_REPORT_TYPES.PRE_INSTALL]: 1,
    [UNIFIED_REPORT_TYPES.UNIFIED]: 2,
    [UNIFIED_REPORT_TYPES.TRACKER]: 3,
    [UNIFIED_REPORT_TYPES.SKAN]: 4,
    [UNIFIED_REPORT_TYPES.NETWORK]: 5,
};
export const SORTING_INDEX_TO_DISPLAY_NAME = {
    [SORTING_INDEX_MAP[UNIFIED_REPORT_TYPES.UNIFIED]]: UNIFIED_DISPLAY_NAME,
    [SORTING_INDEX_MAP[UNIFIED_REPORT_TYPES.TRACKER]]: TRACKER_DISPLAY_NAME,
    [SORTING_INDEX_MAP[UNIFIED_REPORT_TYPES.SKAN]]: SKAN_DISPLAY_NAME,
    [SORTING_INDEX_MAP[UNIFIED_REPORT_TYPES.NETWORK]]: NETWORK_DISPLAY_NAME,
};
export const UNIFIED_REPORT_TYPES_TO_DISPLAY_NAME = {
    [UNIFIED_REPORT_TYPES.UNIFIED]: UNIFIED_DISPLAY_NAME,
    [UNIFIED_REPORT_TYPES.TRACKER]: TRACKER_DISPLAY_NAME,
    [UNIFIED_REPORT_TYPES.SKAN]: SKAN_DISPLAY_NAME,
    [UNIFIED_REPORT_TYPES.NETWORK]: NETWORK_DISPLAY_NAME,
};

export const REPORT_LOAD_TIME_SLOW_CHART = 20000; // 20 seconds

export const FIELD_GROUP_HOLDER_DEFAULT_STYLE = {
    backgroundColor: 'var(--white)',
    border: 'solid 1px var(--gray500)',
};
