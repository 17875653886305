import { reportTypes } from '../../utils/reports';
import {
    getBaseConfig,
    getEmptyStateConfig,
    shelfCohortMetrics,
    shelfCreativeMetadataDimensions,
    shelfCustomDimensions,
    shelfCustomEvents,
    shelfDimensions,
    shelfDiscrepancyMetrics,
    shelfGovernanceDimensions,
    shelfMetrics,
    shelfSourceDimensions,
    shelfTechnicalSpecsDimensions,
    shelfTimeBreakdowns,
} from './baseReportConfig';
import { shelfPeriods } from './shelfPeriods';

const type = reportTypes.creativeExplore;
const path = '/react/creative-explore';
const basicConfig = getBaseConfig(type, 'STATIC.PAGE_HEADERS.CREATIVE_EXPLORE');

export default {
    ...basicConfig,
    path,
    chart: {
        enabled: false,
        show: false,
    },
    pivotTable: {},
    header: {
        ...basicConfig.header,
        subText: {
            faqLink: 'https://support.singular.net/hc/en-us/articles/4408240139163',
        },
        emptyStateHeaderData: getEmptyStateConfig('CREATIVE EXPLORE'),
    },
    fieldWarnings: {},
    shelf: [
        shelfDimensions,
        shelfTechnicalSpecsDimensions,
        shelfCustomDimensions,
        shelfSourceDimensions,
        shelfCreativeMetadataDimensions,
        shelfMetrics,
        shelfDiscrepancyMetrics,
        shelfCohortMetrics,
        shelfCustomEvents,
        shelfPeriods,
        shelfTimeBreakdowns,
        shelfGovernanceDimensions,
    ],
};
